<template>
  <div class="page designs-page">
    <TheDesignCard v-for="(design, index) in designsByGender" :key="index" :design="design" />
  </div>
</template>

<script>
import TheDesignCard from "@/components/TheDesignCard"
import { mapActions, mapState } from 'vuex';
export default {
  name: "TheDesignPage",
  methods: {
    ...mapActions("navigation", ["setNavigationItems"]),
    ...mapActions(["setFrontSide", "resetGenderFromSelectedStyle"]),
  },
  computed: {
    ...mapState(["activeGender", "styles", "selectedStyle"]),
    designsByGender() {
      return this.selectedStyle.design
    }
  },
  mounted() {
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
    this.setFrontSide()
    this.resetGenderFromSelectedStyle()
  },
  data() {
    return {
      navigationItems: ["Gender", "Style", "Colors", "Design"],
    };
  },
  components: {
    TheDesignCard
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .designs-page {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
</style>