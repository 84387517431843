<template>
  <div class="save-code-container">
    <div class="save-code-title">Access Previous Designs</div>
    <div class="save-code-input">
      <input
        name="txtSaveCode"
        type="text"
        class="save-code-text"
        maxlength="13"
        placeholder="Enter Save Code"
        v-model="saveCodeValue"
      />
    </div>
    <button class="save-code-btn btn btn-black" @click="validateSaveCode">
      View design
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "TheSavedDesign",
  computed: {
    ...mapState("customizations", ["saveCode"]),
    saveCodeValue: {
      get() {
        return this.saveCode;
      },
      set(newValue) {
        this.setField({
          field: "saveCode",
          value: newValue.trim(),
        });
      },
    },
  },
  methods: {
    ...mapActions("customizations", ["setField", "validateSaveCode"]),
  },
};
</script>

<style lang="scss" scoped>
#app {
  .save-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3em;
    .save-code-title {
      font-weight: bold;
    }
    .save-code-input {
      margin: 0 5px;
      input {
        border-radius: 5px;
        padding: 3px;
        border-width: 1px;
      }
    }
    .save-code-btn {
      color: #fff;
      background-color: #003464;
      border-color: #003464;
      display: inline-block;
      padding: 6px 13px;
      margin-bottom: 0;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;
      border-radius: 4px;
      text-transform: uppercase;
    }
  }
}
</style>