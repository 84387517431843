<template>
  <div
    class="navigation-item"
    :class="{ 'active-route': activeRoute, disabled: !enabled }"
    @click="itemNavigation"
  >
    <span>{{ item }}</span>
    <div class="navigation-active-decoration" v-if="activeRoute">&nbsp;</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "TheNavigationItem",
  props: ["item"],
  computed: {
    ...mapState(["loading", "activeGender", "selectedStyle", "selectedDesign"]),
    ...mapState("navigation", ["enabledNavigationItems"]),
    ...mapState("layouts", ["activeFrontLayout", "activeBackLayout"]),
    ...mapState("customizations", ["sentCustomizations"]),
    itemRouteName() {
      return "The" + this.item + "Page";
    },
    routeName() {
      return this.$route.name;
    },
    activeRoute() {
      return this.itemRouteName === this.routeName;
    },
    enabled() {
      //return this.enabledNavigationItems.indexOf(this.item) < 0
      if (this.loading) return false
      let enabled = false
      switch (this.item) {
        case "Gender": {
          enabled = true
          break
        }
        case "Style": {
          enabled = (this.activeGender != null )
          break
        }
        case "Colors":
        case "Design": {
          enabled = (this.selectedStyle != null)
          break
        }
        case "Layout": {
          enabled = (this.selectedStyle != null) && (this.selectedDesign != null)
          break
        }
        case "Customize": {
          enabled = (this.selectedStyle != null) && (this.selectedDesign != null) && (this.activeFrontLayout != null) && (this.activeBackLayout != null)
          break
        }
        case "Roster": {
          enabled = this.sentCustomizations && (this.selectedStyle != null) && (this.selectedDesign != null) && (this.activeFrontLayout != null) && (this.activeBackLayout != null)
        }
      }
      return enabled
    }
  },
  methods: {
    ...mapActions("navigation", ["navigate"]),
    //Navigates to page if allowed
    itemNavigation() {
      if (this.loading || this.activeRoute || !this.enabled) return false
      this.navigate({
        destination: this.itemRouteName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .navigation-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 140px;
    height: 100%;
    align-items: center;
    font-family: "Forza";
    font-size: 17px;
    font-weight: 100;
    cursor: pointer;
    padding: 5px 0;
    span {
      font-family: "Forza";
    }
    &.disabled {
      cursor: not-allowed;
      span {
        opacity: .5;
      }
    }
    .navigation-active-decoration {
      background-color: #4b4f87;
      position: absolute;
      left: 0;
      bottom: -9px;
      height: 9px;
      width: 100%;
      border-radius: 5px;
      @media screen and (max-width: 720px) {
        bottom: -6px;
        height: 6px;
      }
    }
    &.active-route {
      font-weight: bold;
      cursor: not-allowed;
    }
    @media screen and (max-width: 1050px) {
      width: auto;
      padding: 5px 20px;
    }
    @media screen and (max-width: 720px) {
      width: auto;
      font-size: 14px;
      padding: 10px 10px;
    }
    @media screen and (max-width: 525px) {
      width: auto;
      font-size: 12px;
      padding: 10px 5px;
    }

    @media screen and (max-width: 450px) {
      width: auto;
      font-size: 11px;
      padding: 10px 5px;
    }
  }
}
</style>