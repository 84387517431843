<template>
  <div class="graphic-item" @click="select">
    <img class="graphic-item-image" :src="graphic.PreviewImageUrl" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TheGraphicItem",
  props: ["graphic"],
  methods: {
    ...mapActions("graphics", ["requestImageAsBase64", "setApiLogo"]),
    select() {
      /*this.requestImageAsBase64({
        url: this.graphic.PreviewImageUrl,
        original: this.graphic.FullSizeImageUrl,
      });
      */
     this.setApiLogo({
        FullSizeImageUrl: this.graphic.FullSizeImageUrl,
        PreviewImageUrl: this.graphic.PreviewImageUrl,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  .graphic-item {
    width: 120px;
    height: 120px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
    box-shadow: 0px 3px 6px #00000040;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    margin: 5px;
    cursor: pointer;
    .graphic-item-image {
      max-width: 100%;
      max-height: 100%;
    }
    &:hover {
      box-shadow: 0px 3px 6px #000000;
    }
  }
}
</style>