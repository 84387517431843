import axios from "axios"
import router from "@/router"


const endPointURL = process.env.VUE_APP_FLASH_API,
  apiUrl = process.env.VUE_APP_ENDPOINT

const customizations = {
  namespaced: true,
  state: {
    sentCustomizations: false,
    productOrderId: "1",
    saveCode: "",
    /*uuid: ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)),*/
    session: null,
    redirectURL: null,
    redirectURLCancel: null,
    finalInfo: null,
    debug: "Loading",
    debugEnabled: false,
  },

  getters: {
  },

  actions: {
    startSession({ commit, dispatch }, payload) {
      commit("startSession", payload)
      dispatch("getProductOrderId", payload)
    },
    setField({ commit }, payload) {
      commit("setField", payload)
    },
    initSentCustomizations({ commit }) {
      commit("setField", {
        field: "sentCustomizations",
        value: false
      })
    },
    enableSentCustomizations({ commit }) {
      commit("setField", {
        field: "sentCustomizations",
        value: true
      })
    },
    createFlashBuilderSession({ dispatch }) {
      const sessionVariable = router.currentRoute.query.sessionVariable

      //dispatch("startLoading", null, { root: true });
      /*const sessionObject = {
        "sessionVariable": state.uuid,
        "redirectURL": "addToCartUrl", //tbd
        "redirectURLCancel": "cancelUrl",  //tbd
      };
      axios.post(
        endPointURL + "/Services/SCGATGService.asmx/CreateSession",
        (sessionObject),
      ).then(() => {
        dispatch("getProductOrderId")
        commit("setDebugInfo", {
          title: "Started session: " + state.uuid,
          content: ""
        })
        dispatch("startSession");
        dispatch("stopLoading", null, { root: true });
      })*/
      if (sessionVariable?.length) {
        dispatch("startSession", {
          session: sessionVariable
        });
      }
      else {
        dispatch("setError", {
          msg: "Missing query parameter: sessionVariable"
        }, { root: true });
      }
      
    },

    selectStyle({ state, commit, dispatch }, payload) {
      if (!payload.ignoreLoading)
        dispatch("startLoading", null, { root: true });

      const requestObject = {
        sessionVariable: state.session,
        StyleNumber: payload.styleId,
        DesignNumber: payload.DesignNumber ? payload.DesignNumber : "",
        SaveCode: payload.SaveCode ? payload.SaveCode : "",
        uiExperience: "",
        Relaunched: true
      }

      axios.get(
        endPointURL + "/Pages/SCGCustomizationPage.aspx",
        {
          params: requestObject
        }
      )
        .then(() => {

          commit("setDebugInfo", {
            title: "Successfully applied STYLE:",
            content: JSON.stringify(requestObject)
          })
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Error sending information to the server"
          }, { root: true });
          console.log(errorInfo)
        })
        .finally(() => {
          if (!payload.ignoreLoading)
            dispatch("stopLoading", null, { root: true });
        })
    },

    applyDesign({ state, commit, dispatch, rootState }, payload) {
      dispatch("startLoading", null, { root: true });
      let requestObject = {
        style: rootState.selectedStyle.style_id,
        design: rootState.selectedDesign.name,
        design_id: rootState.selectedDesign.id,
        layout: payload.layout.code
      }
      //get mapped design id from our backend
      axios.get(
        apiUrl + "api/sns/flash-id",
        {
          params: requestObject
        }
      )
      .then((res) => {
        //update available design for all genders
        const relatedBackgrounds = []
        const relatedSizes = []
        const response = res.data
        const backgrounds = response.related.backgrounds
        const sizes = response.related.sizes
        const keys = Object.keys(backgrounds)

        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          const shadows = backgrounds[key]
          const genderSizes = sizes[key]

          for (let indexy = 0; indexy < Object.keys(shadows).length; indexy++) {
            const shadowKey = Object.keys(shadows)[indexy]
            const shadow = shadows[shadowKey]
            if (shadow != null) {
              relatedBackgrounds[shadowKey] = shadow
            }
          }

          for (let indexy = 0; indexy < Object.keys(genderSizes).length; indexy++) {
            const sizeKey = Object.keys(genderSizes)[indexy]
            const size = genderSizes[sizeKey]
            if (size != null) {
              relatedSizes[sizeKey] = size
            }
          }

        }
        dispatch("updateGenderDesign", {
          backgrounds: relatedBackgrounds,
          related_designs: response.related.designs,
          sizes: relatedSizes,
          prices: response.related.prices
        }, { root: true})
        //then post it to the api
        const retailProductId = res.data.flash_id,
          designName = res.data.design_name,
          designId = res.data.design_id
        requestObject = {
          "sessionVariable": state.session,
          'designId': designId,
          'designName': designName,
          'skuDesignName': '5B', 
          'urlDesignName': rootState.selectedDesign.name.toLowerCase().trim().replace(" ", "_"),
          'retailProductId': retailProductId.toString(),
          'primaryColorEnabled': true, 
          'secondaryColorEnabled': true, 
          'tertiaryColorEnabled': true, 
          'defaultTeamName': 'TEAMNAME', 
          'defaultPlayerName': 'PLAYERNAME', 
          'defaultPlayerNumber': '00', 
          'defaultPrimaryColorId': 2, 
          'defaultSecondaryColorId': 4, 
          'defaultTertiaryColorId': 5, 
          'defaultTeamNameColorId': 391, 
          'defaultTeamNameOutLineColorId': 0, 
          'defaultPlayerNameColorId': 396, 
          'defaultPlayerNameOutlineColorId': 0, 
          'defaultPlayerNoColorId': 23, 
          'defaultPlayerNoOutlineColorId': 0, 
          'requiresUploadedGraphic': payload.layout.graphicRequired
        }
        axios.post(
          endPointURL + "/Services/SCGService.asmx/ApplyDesignBySession",
          requestObject,
        )
          .then(() => {
            commit("setDebugInfo", {
              title: "Successfully applied DESIGN ",
              content: JSON.stringify(requestObject)
            })
          })
          .catch((errorInfo) => {
            dispatch("setError", {
              msg: "Error sending information to the server"
            }, { root: true });
            console.log(errorInfo)
          })
          .finally(() => {
            dispatch("stopLoading", null, { root: true });
          })
      })
      //error getting design id
      .catch((errorInfo) => {
        dispatch("setError", {
          msg: "Error getting design information from the server"
        }, { root: true });
        console.log(errorInfo)
      })
    },

    applyCustomizations({ rootState, dispatch, state, commit }) {
      dispatch("startLoading", null, { root: true });
      const graphicRequired = (rootState.layouts.activeFrontLayout.code === "front-custom-graphic" || rootState.layouts.activeFrontLayout.code === "left-custom-graphic")
      const requestObject = {
        "productOrderId": state.productOrderId.toString(),
        "teamName": !graphicRequired ? rootState.teamName.value.trim().toUpperCase() : "",
        "playerName": rootState.roster[0].name ? rootState.roster[0].name.trim().toUpperCase() : "",
        "playerNumber": rootState.roster[0].number ? rootState.roster[0].number.trim() : "",
        "primaryColorId": rootState.color_1.toString(),
        "secondaryColorId": rootState.color_2.toString(),
        "tertiaryColorId": rootState.color_3.toString(),
        "teamNameColorId": rootState.teamName.fill.toString(),
        "teamNameOutlineColorId": rootState.teamName.outline.toString(),
        "playerNameColorId": rootState.playerName.fill.toString(),
        "playerNameOutlineColorId": rootState.playerName.outline.toString(),
        "playerNoColorId": rootState.number.fill.toString(),
        "playerNoOutlineColorId": rootState.number.outline.toString(),
        "uploadedGraphicPreviewUrl": graphicRequired ? rootState.graphics.converteditedFile : "",
        "uploadedGraphicFullSizeUrl": graphicRequired ? (rootState.graphics.originalFile ? rootState.graphics.originalFile : null) : ""
      }
      axios.post(
        endPointURL + "/Services/SCGService.asmx/ApplyCustomization",
        requestObject,
      )
        .then(() => {
          //request img proof?
          //https://qa-ss.teamworkathletic.com/Services/SCGService.asmx/GetProductViewLiquidPixelUrls
          axios.post(
            endPointURL + "/Services/SCGService.asmx/GetProductViewLiquidPixelUrls",
            {
              productOrderId: state.productOrderId
            },
          )
          .then(() => {
            commit("setDebugInfo", {
              title: "Successfully applied CUSTOMIZATIONS:",
              content: JSON.stringify(requestObject)
            })
          })
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Error sending information to the server"
          }, { root: true });
          console.log(errorInfo)
        })
        .finally(() => {
          dispatch("stopLoading", null, { root: true });
        })
    },
    getProductOrder({ dispatch, commit }, payload) {
      axios.post(
        endPointURL + "/Services/SCGService.asmx/GetProductOrder",
        {
          productOrderId: payload
        },
      )
      .then((response) => {
        const data = response.data.d
        commit("setReturnURLs", data)
        if (data.Product?.StyleId) {
          //we have a info from a saved code
          dispatch("restoreSavedDesign", data, { root: true })
        }
      })
      .catch((errorInfo) => {
        dispatch("setError", {
          msg: "Session expired"
        }, { root: true });
        console.log(errorInfo)
      })
    },
    getProductOrderId({ commit, dispatch }, payload) {
      axios.post(
        endPointURL + "/Services/SCGService.asmx/GetProductOrderIdBySession",
        {
          sessionVariable: payload.session,
        },
      )
        .then((response) => {
          commit("setProductOrderId", response.data.d.toString())
          dispatch("getProductOrder", response.data.d.toString())
          commit("setDebugInfo", {
            title: "ProductOrderID = " + response.data.d + " obtained for session " + payload.session,
            content: ""
          })
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Session expired."
          }, { root: true });
          console.log(errorInfo)
        })
    },

    applyRoster({ dispatch, state, commit }, rosterItems) {
      dispatch("startLoading", null, { root: true });
      const requestObject = {
        productOrderId: state.productOrderId,
        rosterItems:
        {
          RosterItems: rosterItems,
          SessionVariable: state.session
        }
      }

      axios.post(
        endPointURL + "/Services/SCGService.asmx/SaveDealerCustomizations",
        requestObject,
      )
        .then((response) => {
          commit("setDebugInfo", {
            title: "Successfully applied ROSTER (close session info in console)",
            content: response
          })
          dispatch("closeSession")
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Error sending information to the server"
          }, { root: true });
          console.log(errorInfo)
        })
    },

    closeSession({ rootState, state, dispatch, commit }) {
      dispatch("startLoading", null, { root: true });
      const requestObject = {
        sessionVariable: state.session
      }
      axios.post(
        endPointURL + "/Services/SCGATGService.asmx/CloseSession",
        requestObject,
      )
        .then((response) => {
          const finalData = JSON.parse(response.data.d)
          const graphicRequired = (rootState.layouts.activeFrontLayout.code === "front-custom-graphic" || rootState.layouts.activeFrontLayout.code === "left-custom-graphic")

          const finalObject = {
            savecode: finalData.SaveCode,
            data: {
              OldSaveCode: finalData.OldSaveCode,
              SaveCode: finalData.SaveCode,
              TotalQty: finalData.TotalQty,
              TotalAmount: finalData.TotalAmount,
              rosterItems: finalData.items,
              sessionVariable: state.session,
              customizations: {
                color_1: rootState.color_1,
                color_2: rootState.color_2,
                color_3: rootState.color_3,
                layout: {
                  front: rootState.layouts.activeFrontLayout.label,
                  back: rootState.layouts.activeBackLayout.label,
                },
                logo: {
                  originalFile: rootState.graphics.originalFile,
                  converteditedFile: rootState.graphics.converteditedFile
                },
                teamName: !graphicRequired ? rootState.teamName : null,
                number: rootState.number,
                playerName: rootState.playerName,
                style: {
                  style_id: rootState.selectedStyle.style_id,
                  name: rootState.selectedStyle.name
                },
                design: rootState.selectedDesign.name,
                roster: rootState.roster
              }
            }
          }
          commit("setField", {
            field: "finalInfo",
            value: response
          })

          axios.post(
            apiUrl + "api/sns/savecode",
            finalObject,
          )
          .finally(() => {
            dispatch("navigation/navigate", {
              destination: "TheFinalPage"
            }, { root: true });
          })
            
        })
        .finally(() => {
          dispatch("stopLoading", null, { root: true });
        })
    },

    getSavedCustomizations({ dispatch }, payload) {
      //todo apply stylenumber 
      const sessionVariable = router.currentRoute.query.sessionVariable
      axios.get(
        endPointURL + "/Pages/SCGCustomizationPage.aspx", {
          params: {
            sessionVariable,
            StyleNumber: "",
            DesignNumber: "",
            SaveCode: payload.saveCode,
            uiExperience: "",
            Relaunched: true
          }
        }
      )
      .then(() => {
        dispatch("getProductOrderId", {
          session: sessionVariable
        })
//        dispatch("getProductOrder")
      })
      .catch((errorInfo) => {
        dispatch("setError", {
          msg: "Session expired"
        }, { root: true });
        console.log(errorInfo)
      })
    },

    validateSaveCode({ state, dispatch, commit }) {
      dispatch("startLoading", null, { root: true });
      const requestObject = {
        saveCode: state.saveCode
      }
      axios.post(
        endPointURL + "/Services/SCGService.asmx/ValidateSaveCode",
        requestObject,
      )
        .then((response) => {
          if (response.data.d == true) {
            dispatch("getSavedCustomizations", {
              saveCode: state.saveCode
            })
          }
          else {
            alert('Invalid save code')
            commit("setDebugInfo", {
              title: "invalid save code",
              content: state.saveCode,
              error: true
            })
          }
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Session expired"
          }, { root: true });
          console.log(errorInfo)
        })
    }
  },

  mutations: {
    startSession: (state, payload) => {
      state.session = payload.session
    },
    setDebugInfo: (state, payload) => {
      state.debug = payload
    },
    setProductOrderId: (state, payload) => {
      state.productOrderId = payload
    },
    setReturnURLs: (state, payload) => {
      state.redirectURL = payload.RedirectURL
      state.redirectURLCancel = payload.RedirectURLCancel
    },
    setField: (state, payload) => {
      state[payload.field] = payload.value
    }
  },
}

export default customizations