<template>
  <div class="modal-container">
    <div class="graphics-modal">
      <div class="graphics-header">
        <h3>My custom graphics</h3>
        <div class="close-modal" 
          v-if="!apiLoading" 
          @click="close">
            x
        </div>
      </div>
      <div class="loading-container" v-if="apiLoading">
        <div
          class="background-removal-progress"
          v-if="backgroundRemovalLoading"
        >
          <p>We're analyzing your image</p>
          <TheProgressBar progress="100" />
          <p>Please hold while we work on removing this background.</p>
          <p>This could take up to 30 seconds.</p>
        </div>
        <Loader v-else />
      </div>
      <div
        class="graphics-modal-container"
        v-if="!graphicsLoading && !apiLoading && !editMode"
      >
        <div class="graphics-modal-header">
          <div class="graphics-modal-email">
            <label for="graphic-email">
              {{ graphicLabel }}
            </label>
            <input
              id="graphic-email"
              class="form-input"
              type="email"
              v-model="emailId"
              @keydown="reset"
            />
            <div class="error" v-if="hasEmail && !validEmail & fetchedLogos">
              Invalid email format
            </div>
          </div>
          <div class="graphic-modal-actions">
            <div
              class="btn black-btn graphic-modal-email-action"
              :class="{ disabled: !validEmail}"
              v-if="!fetchedLogos"
              @click="getGraphics"
            >
              <span :title="!validEmail ? 'Invalid email format' : 'Search your graphics'">Continue</span>
            </div>
          </div>
        </div>
        <div class="graphics-modal-content" v-if="fetchedLogos">
          <div class="graphics-modal-body" v-if="hasLogos">
            <hr />
            <div class="available-graphics-container">
              <div class="graphics-pagination" v-if="hasGraphicsPagination">
                <div class="pagination-arrow arrow-left"
                  :class="{ disabled: !hasPrevGraphicsPage}"
                  @click="prevPage()"
                >&lt;
                </div>
                <div class="pagination-arrow arrow-right"
                  :class="{ disabled: !hasNextGraphicsPage}"
                  @click="nextPage()"
                >&gt;
                </div>
              </div>
              <TheGraphicItem
                v-for="graphic in filteredGraphics"
                :key="graphic.Id"
                :graphic="graphic"
              />
            </div>
          </div>
          <div class="graphics-modal-footer">
            <div class="upload-graphic-container">
              <input
                class="hidden"
                type="file"
                ref="fileUpload"
                id="upload-file-input"
                @change="fileChanged"
              />
              <label for="upload-file-input" class="upload-graphic-label">
                <img src="/images/upload.svg" alt="Upload custom graphic" />
                <span> Upload your custom graphic </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="graphics-modal-edit-mode" v-if="!apiLoading && editMode">
        <div class="graphic-modal-edit-mode-content">
          <div class="graphic-modal-edit-panel">
            <div class="graphic-panel-items">
              <div
                class="graphic-panel-item"
                :class="{ active: position === item }"
                v-for="item in Array(9)
                  .fill()
                  .map((_, i) => i + 1)"
                :key="item"
                @click="setPosition(item)"
              >
                <img
                  class="graphic-panel-item-image"
                  :src="
                    '/images/positions/' +
                    item +
                    (position === item ? '_active' : '') +
                    '.svg'
                  "
                />
              </div>
            </div>
            <div class="graphic-zoom-container">
              <input
                class="graphic-zoom"
                type="range"
                min="1"
                :max="sizeRanges.length"
                v-model="zoomLevel"
              />
              <div class="graphic-zoom-label">
                {{ zoomLabel }}
              </div>
            </div>
            <div class="graphic-modal-edit-mode-actions">
              <div
                class="btn black-btn graphic-modal-edit-mode-action"
                @click="removeBg"
              >
                <span>remove background</span>
              </div>
            </div>
          </div>
          <div class="graphic-modal-transparent-bg">
            <div
              id="graphic-preview"
              class="graphic-modal-edit-preview"
              :style="{ alignItems, justifyContent }"
            >
              <img
                class="graphic-modal-edit-preview-image"
                :src="editMode.content"
                v-bind:style="{
                  width: zoomStyle,
                  height: preserveAspectRatio ? 'auto' : zoomStyle,
                }"
              />
            </div>
          </div>
        </div>
        <div class="graphic-modal-edit-mode-actions">
          <div
            class="btn black-btn graphic-modal-edit-mode-action"
            @click="save"
          >
            <span>apply</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
// Converts base64 to png File
*/
function urltoFile(url, filename, mimeType) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

import html2canvas from "html2canvas";
import { mapActions, mapState } from "vuex";
import Loader from "@/components/Loader";
import TheGraphicItem from "@/components/TheGraphicItem";
import TheProgressBar from "@/components/TheProgressBar";
export default {
  name: "TheGraphicsModal",
  methods: {
    ...mapActions("graphics", [
      "requestGraphics",
      "triggerModal",
      "uploadGraphic",
      "uploadGraphicRhino",
      "setPosition",
      "setZoom",
      "setAspectRatio",
      "setApiLogo",
      "setApiLoading",
      "setEditMode",
      "removeBackground",
      "requestBase64AsImage",
    ]),
    nextPage() {
      if (this.hasNextGraphicsPage)
        this.currentGraphicsPage += 1
    },
    prevPage() {
      if (this.hasPrevGraphicsPage)
        this.currentGraphicsPage -= 1
    },
    removeBg() {
      this.setApiLoading(true);
      const fileName = "test";
      const originalUrl = this.editMode.url;
      html2canvas(document.querySelector("#graphic-preview"), {
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: null,
      }).then((canvas) => {
        urltoFile(canvas.toDataURL("image/png"), fileName).then((file) => {
          this.removeBackground({
            file,
            original: originalUrl,
            base64: this.editMode.content,
          });
        });
      });
    },
    save() {
      const originalUrl = this.editMode.url;
      this.setApiLoading(true);
      this.setEditMode(null);
      html2canvas(document.querySelector("#graphic-preview"), {
        allowTaint: true,
        scrollX: 0,
        scrollY: 0,
        backgroundColor: null,
      }).then((canvas) => {
        this.requestBase64AsImage({
          original: originalUrl,
          base64: canvas.toDataURL("image/png"),
        });
        //this.setApiLoading(false);
      });
    },
    fileChanged() {
      if (this.validEmail) {
        this.selectedFile = this.$refs.fileUpload.files[0];
        const fileName = this.selectedFile.name;

        this.uploadGraphic({
          file: this.selectedFile,
          imageName: fileName,
          fileName: fileName,
          AccountNumber: "",
          emailId: this.emailId.trim().toLowerCase(),
        });

        this.currentGraphicsPage = 1
      }
    },
    getGraphics() {
      if (this.validEmail) {
        this.fetchedLogos = true
        this.requestGraphics({
          emailId: this.emailId.trim().toLowerCase(),
        });
      }
    },
    close() {
      this.triggerModal(false);
    },
    reset() {
      this.currentGraphicsPage = 1
      this.fetchedLogos = false
    }
  },
  computed: {
    ...mapState("graphics", [
      "graphicsLoading",
      "apiLoading",
      "backgroundRemovalLoading",
      "apiLogos",
      "editMode",
      "position",
      "zoom",
      "aspectRatio",
    ]),
    graphicLabel() {
      if (!this.hasLogos) {
        if (this.fetchedLogos) {
          return "Your locker is currently empty. Upload graphics from your computer below."
        }
        else {
          return "Enter your email to access or create your graphics library."
        }
      }
      else {
        if (this.fetchedLogos) {
          return "Select a graphic to add to your garment or upload additional graphics to your locker."
        }
        else {
          return "Enter your email to access or create your graphics library."
        }
      }
    },
    validEmail() {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.emailId);
    },
    zoomStyle() {
      return (this.zoom / this.sizeRanges.length).toFixed(2) * 100 + "%";
    },
    preserveAspectRatio: {
      get() {
        return this.aspectRatio;
      },
      set(newValue) {
        this.setAspectRatio(newValue);
      },
    },
    zoomLevel: {
      get() {
        return this.zoom;
      },
      set(newValue) {
        this.setZoom(newValue * 1);
      },
    },
    zoomLabel() {
      return this.sizeRanges[this.zoom - 1];
    },
    alignItems() {
      //returns align-items: $value
      return [1, 2, 3].indexOf(this.position) >= 0
        ? "flex-start"
        : [4, 5, 6].indexOf(this.position) >= 0
        ? "center"
        : "flex-end";
    },
    justifyContent() {
      return [1, 4, 7].indexOf(this.position) >= 0
        ? "flex-start"
        : [2, 5, 8].indexOf(this.position) >= 0
        ? "center"
        : "flex-end";
    },
    graphics() {
      return this.hasLogos &&
        this.apiLogos.graphics &&
        this.apiLogos.graphics.length
        ? this.apiLogos.graphics
        : [];
    },
    filteredGraphics() {
      if (this.graphics.length < 4) {
        return this.graphics
      }
      else {
        //return paginated graphics
        const current = (this.currentGraphicsPage - 1) * 4
        return this.graphics.slice(current, current + 4)
      }
    },
    filteredGraphicPages() {
      return Math.ceil(this.graphics.length / 4)
    },
    hasGraphicsPagination() {
      return this.filteredGraphicPages > 1
    },
    hasNextGraphicsPage() {
      return this.filteredGraphicPages > this.currentGraphicsPage
    },
    hasPrevGraphicsPage() {
      return this.currentGraphicsPage > 1
    },
    hasLogos() {
      return (this.apiLogos != null && this.apiLogos.graphics?.length > 0);
    },
    hasEmail() {
      return this.emailId && this.emailId.trim().length;
    },
    hasDealer() {
      return this.dealerId && this.dealerId.trim().length;
    },
    hasEmailOrDealer() {
      return this.hasEmail || this.dealerId != null;
    },
    firstGraphicEmail() {
      return this.graphics && this.graphics.length
        ? this.graphics[0].Email
        : null;
    },
    emailNotChanged() {
      return this.firstGraphicEmail === this.emailId.trim().toLowerCase();
    },
  },
  mounted() {
    if (this.hasLogos) {
      this.emailId = this.apiLogos.email;
    }
  },
  data() {
    return {
      fetchedLogos: false,
      currentGraphicsPage: 1,
      selectedFile: null,
      emailId: null,
      dealerId: null,
      sizeRanges: ["25%", "50%", "75%", "100%"],
    };
  },
  components: {
    TheGraphicItem,
    Loader,
    TheProgressBar,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .modal-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    .graphics-modal {
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000040;
      max-width: 80vw;
      min-width: 400px;
      .loading-container {
        padding: 25px;
      }
      .black-btn {
        border-radius: 7px;
        text-transform: uppercase;
        margin: 10px 0 25px;
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
      .graphics-header {
        padding: 15px 0;
        text-align: center;
        border-bottom: 2px solid #666;
        h3 {
          text-transform: uppercase;
          margin: 0;
        }
        .close-modal {
          position: absolute;
          right: 15px;
          top: 10px;
          color: white;
          background-color: black;
          justify-content: center;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 5px 13px;
          z-index: 3;
          font-size: 18px;
        }
      }
      .graphics-modal-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 15px;
        .graphic-modal-actions {
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 15px 0 5px;
          .graphic-modal-email-action {
            padding: 5px 25px;
          }
        }
        .graphics-modal-email {
          display: flex;
          flex-direction: column;
          align-items: center;
          label {
            font-family: "SlateStd";
            font-size: 1.1em;
            font-weight: bold;
            padding: 25px 10px;
          }
          .form-input {
            height: 40px;
            box-sizing: border-box;
            padding: 3px 5%;
            font-family: "SlateStd";
            border: none;
            background: #f8f8f8 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            outline: none;
            font-size: 16px;
            color: #2e2e2e;
            width: 90%;
          }
        }
      }
      .graphics-modal-content {
        //max-height: 50vh;
        .graphics-modal-body {
          .available-graphics-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 20px 10px;
            position: relative;
            .graphics-pagination {
              position: absolute;
              width: 95%;
              top: 50%;
              display: flex;
              justify-content: space-between;
              .pagination-arrow {
                padding: 3px 9px;
                background-color: #333;
                color: white;
                border-radius: 15px;
                font-weight: bold;
                cursor: pointer;
                &.disabled {
                  cursor: not-allowed;
                  background-color: #666;
                }
              }
            }
          }
        }
        .graphics-modal-footer {
          .upload-graphic-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 0 25px;
            .upload-graphic-label {
              width: 100%;
              height: 41px;
              background-color: #eee;
              max-width: 300px;
              padding: 10px;
              text-overflow: ellipsis;
              border: 1px solid #ccc;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                padding-left: 5px;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .graphics-modal-edit-mode {
        display: flex;
        flex-direction: column;
        .graphic-modal-edit-mode-content {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex: 1;
          .graphic-modal-edit-panel {
            width: 260px;
            display: flex;
            flex-direction: column;
            padding-right: 25px;
            .graphic-panel-items {
              min-height: 118px;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              .graphic-panel-item {
                width: 33%;
                padding: 10px;
                display: flex;
                justify-content: center;
                .graphic-panel-item-image {
                  cursor: pointer;
                  &.active {
                    cursor: initial;
                  }
                }
              }
            }
            .graphic-zoom-container {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              .graphic-zoom {
                width: 100%;
              }
            }
          }
          .graphic-modal-transparent-bg {
            position: relative;
            width: 260px;
            height: 260px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
            outline: 1px solid;
            .graphic-modal-edit-preview {
              position: absolute;
              left: 0;
              top: 0;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              width: 260px;
              height: 260px;
              margin-right: 20px;
              background: transparent;
              .graphic-modal-edit-preview-image {
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
        .graphic-modal-edit-mode-actions {
          display: flex;
          margin-top: 25px;
          justify-content: center;
          span {
            padding: 0 35px;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .background-removal-progress {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin: 3px 0;
        }
      }
    }
  }
}
</style>