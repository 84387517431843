<template>
  <div
    class="customization-item-wrapper"
    :class="{ 'customization-item-large': customizationItem.large, error: hasError }"
  >
    <div class="customization-item" @click="toggleCustomizationItem">
      <div class="customization-item-preview">
        <img
          class="customization-item-preview-img"
          :src="stateValue.stamp_url"
        />
      </div>
    </div>
    <h5 class="customization-item-title">
      {{ customizationItem.label }}
    </h5>
    <CustomizationList
      v-if="customizationItem.opened && customizationItem.activeSide === side"
      :item="customizationItem"
      :side="side"
      v-on="{ updatedPlayer, closeList }"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomizationList from "@/components/Product/CustomizationList";

export default {
  name: "TeamCustomizationItem",
  props: ["customizationItem", "loopIndex", "side", "teamNameMissing"],
  computed: {
    ...mapGetters(["getStateByName"]),
    stateValue() {
      return this.getStateByName({
        name: this.customizationItem.field,
      });
    },
    hasError() {
      return this.teamNameMissing && this.customizationItem.field === 'teamName'
    }
  },
  methods: {
    ...mapActions(["setSubField", "requestTextStamp", "updateFirstRosterRow"]),
    toggleCustomizationItem() {
      this.$emit("toggleCustomizationItem", {
        field: this.customizationItem.field,
        side: this.side,
      });
    },
    updatedPlayer(payload) {
      this.updateFirstRosterRow({
        field: this.customizationItem.label.replace("player ", ""),
        value: payload.tempValue,
      });
    },
    closeList() {
      /*//Update Colors
      for (let index = 0; index < payload.colorPickers.length; index++) {
        const colorPicker = payload.colorPickers[index];
        this.setSubField({
          field: colorPicker.parent, //teamName, playerName, number
          subfield: colorPicker.field, //fill or outline
          value: colorPicker.tempValue, //colorId
        });
      }
      //Set text value
      this.setSubField({
        field: this.customizationItem.field,
        subfield: "value",
        value: payload.tempValue,
      });
      this.updateFirstRosterRow({
        field: this.customizationItem.label.replace("player ", ""),
        value: payload.tempValue,
      });
      this.requestTextStamp({
        mainField: this.customizationItem.field,
        value: payload.tempValue,
      });*/
      this.toggleCustomizationItem();
    },
  },
  components: {
    CustomizationList,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .customization-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 155px;
    margin-right: 10px;
    &.customization-item-large {
      width: 290px;
    }
    &.error {
      border: 3px solid red;
    }
    .customization-item {
      width: 100%;
      height: 65px;
      box-sizing: border-box;
      -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      background-color: #f2f2f2;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .customization-item-preview {
        max-height: 65px;
        max-width: 100%;
        .customization-item-preview-img {
          max-width: 100%;
          max-height: 60px;
          padding: 7px;
          box-sizing: border-box;
        }
      }
    }
    .customization-item-title {
      margin: 10px 0;
      text-transform: uppercase;
    }
  }
}
</style>