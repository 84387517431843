<template>
  <div class="design-card" :title="design.name" @click="select">
    <div class="design-vector">
      <Vector
        :svgs="svgs"
        :backgrounds="backgrounds"
        :side="activeSide"
        :gender="activeGender"
        :disableStamps="true"
        :forcedDesign="design"
      />
    </div>
    <div class="design-name">{{ design.name }}</div>
  </div>
</template>

<script>
import Vector from "@/components/Vector.vue";

import { mapActions, mapState } from "vuex";

export default {
  name: "TheDesignCard",
  props: ["design"],
  computed: {
    
    ...mapState(["selectedStyle", "activeSide", "activeGender"]),
    
    svgs() {
      return this.design?.svg_data
        ? this.design?.svg_data[this.activeSide]
        : [];
    },
    
    backgrounds() {
      return this.selectedStyle.backgrounds;
    },

  },
  methods: {

    ...mapActions(["setField"]),
    ...mapActions("navigation", ["navigate"]),

    select() {
      //Set active product and move to the next step
      this.setField({
        field: "selectedDesign",
        value: this.design,
      });
    
      this.navigate({
        destination: "TheLayoutPage",
      });
    },

  },
  components: {
    Vector,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .design-card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 225px;
    height: 310px;
    padding: 5px;
    margin: 5px 25px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 1;
    background-color: white;
    .design-vector {
      padding: 10px;
      min-height: 265px;
    }
    .design-name {
      text-align: center;
      font-family: "Forza";
      font-size: 18px;
    }
    &:hover {
      -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 1);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 1);
    }
  }
}
</style>