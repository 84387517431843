<template>
  <div class="page style-page">
    <div class="style-items-container">
      <TheStyleItem v-for="(item, index) in stylesByGender" :key="index" :item="item" />
    </div>
    <TheProductBranding />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TheProductBranding from "@/components/TheProductBranding"
import TheStyleItem from "@/components/TheStylePage/TheStyleItem"
export default {
  name: "TheStylePage",
  methods: {
    ...mapActions("navigation", ["setNavigationItems"]),
    ...mapActions(["resetGenderFromSelectedStyle"])
  },
  computed: {
    ...mapState(["styles", "activeGender"]),
    stylesByGender() {
      return this.styles[this.activeGender]
    }
  },
  mounted() {
    this.resetGenderFromSelectedStyle()
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
  },
  data() {
    return {
      navigationItems: ["Gender", "Style"],
    };
  },
  components: {
    TheProductBranding,
    TheStyleItem
  }
};
</script>

<style lang="scss" scoped>
  .style-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .style-items-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
</style>