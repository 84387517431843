<template>
  <div class="gender-box" :title="box.title" @click="select" :class="{ active: isActive }">
    <div class="gender-box-body">
      <img
        :class="'gender-box-image gender-box-image-' + (index * 1 + 1)"
        :src="'/images/' + img"
        v-for="(img, index) in box.images"
        :key="index"
        :alt="box.title + ' Image ' + (index * 1 + 1)"
      />
    </div>
    <div class="gender-box-footer">
      <div class="gender-box-title">
        {{ box.title }}
      </div>
      <div class="gender-box-description">
        {{ box.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState(["activeGender", "roster"]),
    isActive() {
      return this.activeGender?.toLowerCase() === this.box.genderName.toLowerCase()
    }
  },
  methods: {
    ...mapActions(["setField", "resetStyle"]),
    ...mapActions("navigation", ["navigate"]),
    select() {
      if (!this.isActive) {
        
        this.resetStyle()

        const rosterIsClean = (this.roster.length === 0) || (this.activeGender === this.box.genderName)
        //clear roster if has old info from another gender
        if (!rosterIsClean) {
          this.setField({
            field: "roster",
            value: []
          })
        }
        
        //set active gender and move to the next step
        this.setField({
          field: "activeGender",
          value: this.box.genderName
        })
        this.navigate({
          destination: "TheStylePage"
        })
      }
      else {
        this.navigate({
          destination: "TheStylePage"
        })
      }
    }
  },
  name: "TheGenderBox",
  props: ["box"],
};
</script>

<style lang="scss" scoped>
.gender-box {
  width: 90%;
  max-width: 525px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 10px;
  margin: 5px 25px;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;
  padding-top: 0;
  &.active {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
  }
  .gender-box-body {
    display: flex;
    justify-content: center;
    padding: 5px;
    padding-top: 0;
    .gender-box-image {
      min-height: 300px;
    }
    .gender-box-image-2 {
      margin-left: -25px;
    }
  }
  &:hover {
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
  }
  .gender-box-footer {
    .gender-box-title {
      font-family: "ForzaBold";
      text-align: center;
      font-size: 26px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 5px;
    }
    .gender-box-description {
      text-align: center;
      font-size: 18px;
    }
  }
}
</style>