<template>
  <div class="product-branding">
    <img class="product-branding-image" src="/images/product_branding_a.png" />
    <img class="product-branding-image" src="/images/product_branding_b.png" />
  </div>
</template>

<script>
export default {
  name: "TheProductBranding"
}
</script>

<style lang="scss" scoped>
  #app {
    .product-branding {
      display: flex;
      justify-content: center;
      margin: 50px 0;
      align-items: center;
      .product-branding-image {
        padding: 0 10px;
        max-width: 50%;
        max-height: 75px;
      }
    }
  }
</style>