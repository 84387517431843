<template>
  <div class="page layouts-page">
    <div class="layouts-container">
      <TheLayoutCard
        v-for="(layout, index) in layoutsByLayoutSide"
        :key="index"
        :layout="layout"
      />
    </div>
    <div class="layout-sides-container">
      <div
        class="layout-side-toggle layout-side-toggle-front"
        :class="{ active: activeSide === 'front' }"
      >
        Front
      </div>
      <div
        class="layout-side-toggle layout-side-toggle-back"
        :class="{ active: activeSide === 'back' }"
      >
        Back
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TheLayoutCard from "@/components/TheLayoutPage/TheLayoutCard";
export default {
  name: "TheLayoutPage",
  methods: {
    ...mapActions("navigation", ["setNavigationItems"]),
    ...mapActions(["setFrontSide", "setBackSide"]),
    ...mapActions("layouts", ["resetActiveLayouts"]),
  },
  computed: {
    ...mapState([
      "activeGender",
      "styles",
      "activeSide",
      "selectedDesign",
      "selectedStyle",
    ]),
    ...mapState("layouts", [
      "layouts",
      "activeFrontLayout",
      "activeBackLayout",
    ]),
    designsByGender() {
      return this.selectedStyle.design;
    },
    layoutsByLayoutSide() {
      return this.layouts[this.activeSide];
    },
  },
  mounted() {
    this.setFrontSide();
    this.resetActiveLayouts();
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
  },
  data() {
    return {
      navigationItems: ["Gender", "Style", "Colors", "Design", "Layout"],
    };
  },
  components: {
    TheLayoutCard,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .layouts-page {
    display: flex;
    flex-direction: column;
    .layouts-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .layout-sides-container {
      display: flex;
      justify-content: center;
      margin: 50px;
      .layout-side-toggle {
        border: 1px solid #000000;
        padding: 10px 35px;
        background: #f6f6f6 0% 0% no-repeat padding-box;
        z-index: 1;
        opacity: 0.5;
        font-size: 21px;
        text-transform: uppercase;
        &.layout-side-toggle-front {
          margin-right: -10px;
          border-radius: 25px 0 0 25px;
        }
        &.layout-side-toggle-back {
          margin-left: -10px;
          border-radius: 0 25px 25px 0;
        }
        &.active {
          z-index: 2;
          border-radius: 25px;
          opacity: 1;
        }
      }
    }
  }
}
</style>

