import axios from "axios"

const endPointURL = process.env.VUE_APP_FLASH_API,
  apiUrl = process.env.VUE_APP_ENDPOINT

const graphics = {
  namespaced: true,
  state: {
    graphicsLoading: false,
    apiLoading: false,
    backgroundRemovalLoading: false,
    modalOpened: false,
    file: null,
    fileSize: null,
    originalFile: null,
    converteditedFile: null,
    apiLogos: null,
    editMode: false,
    position: 2, //from 1 to 9 - 2 is top center
    zoom: 4,
    aspectRatio: true,
    rendering: false,
  },

  getters: {
  },

  actions: {
    /* 
    // Removes background from an image
    */
    removeBackground({ dispatch, commit }, payload) {
      dispatch("setBackgroundRemovalLoading", true)
      dispatch("setApiLoading", true)
      commit("setEditMode", null)
      let formData = new FormData
      formData.append("base64", payload.base64.replace("data:image/png;base64,", ""))
      axios.post(
        apiUrl + "/api/remove-background",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          commit("setEditMode", {
            content: "data:image/png;base64," + response.data,
            url: payload.original
          })
        })
        .finally(() => {
          dispatch("setApiLoading", false)
          dispatch("setBackgroundRemovalLoading", false)
        })
    },
    /*
    // Uploads graphic to Peter's endpoint
    */
    uploadGraphic({ commit, dispatch }, payload) {
      dispatch("setApiLoading", true)
      //commit("setFile", payload.file)

      let formData = new FormData
      formData.append("File", payload.file)
      axios.post(
        endPointURL + "Services/SCGGraphicService.svc/UploadGraphicFile" +
        "?emailId=" + payload.emailId +
        "&AccountNumber=&fileName=" + payload.fileName +
        "&imageName=" + payload.imageName,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
        .then((response) => {
          if (response.data.Error == null) {
            commit("appendGraphic", response.data.ResponseData)
            //If successfully added file to Peters API
            //we need to have a base64 of the image, the previewimageurl is not enought
            //because we cannot use tainted img in html2canvas
           ///// dispatch("uploadGraphicRhino", payload)
            dispatch("setApiLoading", false)
          }
          else {
            alert("Error: " + response.data.Error.ErrorDescription)
            dispatch("setApiLoading", false)
          }
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Error sending information to the server"
          }, { root: true });
          console.log(errorInfo)
        })
    },
    requestBase64AsImage({ dispatch, commit }, payload) {
      //I send a PNG url to Rhino and I get a base64
      dispatch("setApiLoading", true)
      axios.post(
        apiUrl + "/api/sns/to-png",
        {
          base64: payload.base64.replace("data:image/png;base64,", "")
        }
      )
        .then((response) => {
          commit("setApiLogo", {
            FullSizeImageUrl: payload.original,
            PreviewImageUrl: response.data,
          })
        })
        .finally(() => {
          dispatch("setApiLoading", false)
        })
    },

    requestImageAsBase64({ dispatch, commit }, payload) {
      //I send a PNG url to Rhino and I get a base64
      dispatch("setApiLoading", true)
      axios.get(
        apiUrl + "/api/sns/to-base64",
        {
          params: payload
        }
      ).then((response) => {
        //I use the base64 for the modal which will use html2canvas
        commit("setEditMode", {
          content: "data:image/png;base64," + response.data,
          url: payload.original
        })
      })
        .finally(() => {
          dispatch("setApiLoading", false)
        })
    },
    uploadGraphicRhino({ dispatch, commit }, payload) {
      dispatch("setApiLoading", true)
      commit("setFile", payload.file)

      let formData = new FormData
      formData.append("file", payload.file)
      axios.post(
        apiUrl + "api/v2/upload/logo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          //commit("setEditMode", response.data)
          commit("setApiLogo", {
            PreviewImageUrl: response.data.ResponseData.PreviewImageUrl,
            FullSizeImageUrl: response.data.ResponseData.FullSizeImageUrl
          })
        })
        .finally(() => {
          dispatch("setApiLoading", false)
        })
    },
    setLoading({ commit }, loadingState) {
      commit("setLoading", loadingState)
    },
    setApiLoading({ commit }, loadingState) {
      commit("setApiLoading", loadingState)
    },
    setBackgroundRemovalLoading({ commit }, loadingState) {
      commit("setBackgroundRemovalLoading", loadingState)
    },
    triggerModal({ commit }, modalState) {
      commit("triggerModal", modalState)
    },
    setApiGraphic({ commit }, payload) {
      commit("setApiGraphic", payload)
    },
    setPosition({ commit }, payload) {
      commit("setPosition", payload)
    },
    setZoom({ commit }, payload) {
      commit("setZoom", payload)
    },
    setAspectRatio({ commit }, payload) {
      commit("setAspectRatio", payload)
    },
    requestGraphics({ commit, dispatch }, payload) {
      //requests logos to flash endpoint using email or dealerid
      commit("setApiLoading", true)
      axios.post(
        //endPointURL + 'Services/SCGGraphicService.svc/GetLockerFileList', {
        apiUrl + "api/sns/locker-file-list", {
        dealerId: payload.dealerId ? payload.dealerId : "",
        email: payload.emailId ? payload.emailId : ""
      }
      )
        .then((response) => {
          commit("setApiGraphics", {
            graphics: response.data.ResponseData,
            email: payload.emailId
          })
        })
        .catch((errorInfo) => {
          dispatch("setError", {
            msg: "Error getting information from the server"
          }, { root: true });
          console.log(errorInfo)
        })
        .finally(() => {
          commit("setApiLoading", false)
        })
    },
    setApiLogo({ commit }, payload) {
      commit("setApiLogo", payload)
    },
    setEditMode({ commit }, payload) {
      commit("setEditMode", payload)
    }
  },

  mutations: {
    appendGraphic: (state, payload) => {
      let currentGraphics = state.apiLogos.graphics
      let reversed = currentGraphics.reverse()
      let result = [...reversed, payload]
      state.apiLogos.graphics = result.reverse()
    },
    setUploadedGraphic: (state, payload) => {
      state.originalFile = payload.url
      state.converteditedFile = payload.content
    },
    setFile: (state, file) => {
      let fSExt = new Array("Bytes", "KB", "MB", "GB"),
        i = 0,
        _size = file.size;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }
      state.file = file
      state.fileSize = Math.round(_size * 100) / 100 + " " + fSExt[i]
    },
    setApiLoading: (state, loadingState) => {
      state.apiLoading = loadingState
    },
    setBackgroundRemovalLoading: (state, loadingState) => {
      state.backgroundRemovalLoading = loadingState
    },
    setLoading: (state, loadingState) => {
      state.graphicsLoading = loadingState
    },
    setField: (state, payload) => {
      state[payload.field] = payload.value
    },
    triggerModal: (state, modalState) => {
      state.modalOpened = modalState
      state.editMode = false
    },

    setApiGraphics: (state, payload) => {
      state.apiLogos = payload
    },

    setApiGraphic: (state, payload) => {
      state.converteditedFile = payload.PreviewImageUrl
      state.modalOpened = false
    },
    setEditMode: (state, payload) => {
      state.editMode = payload
    },
    setPosition: (state, payload) => {
      state.position = payload
    },
    setZoom: (state, payload) => {
      state.zoom = payload
    },
    setAspectRatio: (state, payload) => {
      state.aspectRatio = payload
    },
    //test
    setApiLogo: (state, payload) => {
      state.converteditedFile = payload.PreviewImageUrl
      state.originalFile = payload.FullSizeImageUrl
      state.modalOpened = false
    }
  }
}

export default graphics