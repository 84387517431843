<template>
  <div class="customization-list">
    <p class="customization-list-help">{{ helpText }}</p>
    <div class="form-control">
      <input
        class="text-input"
        :maxlength="maxLength"
        type="text"
        v-model="tempValue"
        @keyup="debounceName"
        @focus="checkPlaceholder"
        :placeholder="placeholder"
      />
    </div>
    <div class="color-pickers-container">
      <ColorPicker
        v-for="(colorPicker, index) in colorPickers"
        v-on="{ togglePicker, setTempColor }"
        :key="index"
        :loopIndex="index"
        :colorPicker="colorPicker"
      />
    </div>
    <div class="black-btn" @click="closeList">
      <span>Close</span>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker";
import { mapActions, mapState } from "vuex";
import { debounce } from "debounce";

export default {
  name: "CustomizationList",
  props: ["item"],
  computed: {
    ...mapState(["roster", "teamName", "number", "playerName"]),
    placeholder() {
      if (this.item.field === "teamName") {
        return "Team Name"
      }
      else if (this.item.field === "number") {
        return "00"
      }
      return "Player Name"
    },
    maxLength() {
      return this.item.field === "number" ? 2 : 15;
    },
    helpText() {
      return this.item.label.toUpperCase() + 'S are Full Block Only 3" Tall';
    },
  },
  methods: {
    ...mapActions(["setSubField", "requestTextStamp"]),
    ...mapActions("customizations", ["initSentCustomizations"]),
    debounceName: debounce(function (e) {
      this.initSentCustomizations()

      this.setSubField({
        field: this.item.field,
        value: e.target.value
          .replace(/(?!\w|\s)./g, "")
          .replace(/\s+/g, " ")
          .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2"), //remove special chars and double white spaces
        subfield: 'value'
      });
      this.requestTextStamp({
        mainField: this.item.field
      });

      //Update first roster row if we are updating a player name or number
      if (["playerName", "number"].indexOf(this.item.field) >= 0) {
        this.$emit("updatedPlayer", {
          colorPickers: this.colorPickers,
          tempValue: this.tempValue,
        })
      }
    }, 1000),
    togglePicker(key) {
      //hide other opened pickers
      for (let index = 0; index < this.colorPickers.length; index++) {
        if (index !== key) {
          this.colorPickers[index].opened = false;
        }
      }
      this.colorPickers[key].opened = !this.colorPickers[key].opened;
    },
    checkPlaceholder() {
      if (this.tempValue.toLowerCase().trim() === this.placeholder.toLowerCase()) {
        this.initSentCustomizations()
        this.tempValue = ""
        this.setSubField({
          field: this.item.field,
          value: "",
          subfield: 'value'
        });
        this.requestTextStamp({
          mainField: this.item.field
        });
         //Update first roster row if we are updating a player name or number
          if (["playerName", "number"].indexOf(this.item.field) >= 0) {
            this.$emit("updatedPlayer", {
              colorPickers: this.colorPickers,
              tempValue: this.tempValue,
            })
          }
      }
    },
    closeList() {
      this.$emit("closeList")
    },
    setTempColor(payload) {
      this.colorPickers[payload.loopIndex].tempValue = payload.color.id;
    },
  },
  data() {
    return {
      tempValue: "",
      colorPickers: [
        {
          field: "fill",
          opened: false,
          parent: this.item.field,
          tempValue: 24,
        },
        {
          field: "outline",
          opened: false,
          parent: this.item.field,
          tempValue: 22,
        },
      ],
    };
  },
  mounted() {
    if (this.item.field === "teamName") {
      this.tempValue = this.teamName.value;
    } else {
      this.tempValue =
        this.item.field === "number"
          ? this.roster[0].number
          : this.roster[0].name;
    }
  },
  watch: {
    tempValue(newValue) {
      if (this.item.field === "number") {
        //converts field into only numbers and updates it if changed
        this.tempValue = newValue.replace(/\D/g, "");
      }
    },
  },
  components: {
    ColorPicker,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .customization-list {
    border: 1px solid #d6d6d6;
    position: absolute;
    left: 0;
    background-color: #ffffff;
    z-index: 1;
    padding: 10px 30px;
    width: 385px;
    top: -320px;
    box-sizing: border-box;
    .customization-list-help {
      font-family: "SlateStd";
      text-align: center;
    }
    .form-control {
      width: 100%;
      display: flex;
      justify-content: center;
      .text-input {
        width: 100%;
        height: 65px;
        font-size: 35px;
        text-transform: uppercase;
        text-align: center;
        background-color: #f6f6f6;
        outline: none;
        border: none;
        -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
      }
    }
    .color-pickers-container {
      display: flex;
      justify-content: center;
      margin: 25px;
      flex-direction: row;
      @media screen and (max-width: 720px) {
        flex-direction: column;
      }
    }
  }
}
</style>