<template>
  <div class="roster-row" :class="{ 'active-row': rowKey === activeRowKey }" v-if="rosterRow">
    <div class="roster-row-column roster-row-column-name" v-if="backLayoutHasName">
      <input
        type="text"
        class="roster-field"
        v-on:input="debounceName"
        v-model="tempName"
        maxlength="20"
        @focus="focusRow"
      />
    </div>
    <div class="roster-row-column roster-row-column-number" v-if="backLayoutHasNumber">
      <input
        type="text"
        class="roster-field"
        v-on:input="debounceNumber"
        v-model="tempNumber"
        maxlength="2"
        @focus="focusRow"
      />
    </div>
    <div class="roster-row-column roster-row-column-gender">
      <select class="roster-field roster-select" v-model="fieldGender" @focus="focusRow" :class="{ 'error': !fieldGender.length && showErrors }">
        <option v-for="(gender, index) in genders" :key="index">
          {{ gender.toLowerCase() }}
        </option>
      </select>
    </div>
    <div class="roster-row-column roster-row-column-size">
      <select class="roster-field roster-select" v-model="fieldSize" @focus="focusRow" :class="{ 'error': !fieldSize.length && showErrors }">
        <option v-for="(size, index) in sizesByGender" :key="index">
          {{ size }}
        </option>
      </select>
    </div>
    <div class="roster-row-column roster-row-column-qty">
      <input
        type="number"
        class="roster-field"
        :class="{ 'error': fieldQuantity < 1 && showErrors }"
        v-model="fieldQuantity"
        min="1"
        max="999"
        maxlength="3"
        @focus="focusRow"
      />
    </div>
    <div class="roster-row-column roster-row-column-price">
      ${{ totalPrice }}
    </div>
    <div class="roster-row-column roster-actions">
      <div class="roster-action" @click="remove()">
        <img src="/images/trash.png" title="Remove Player" alt="Remove Player" class="roster-action-icon">
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "debounce";

export default {
  name: "RosterRow",
  props: ["rowKey", "designGender", "productSizes", "rosterRow", "activeRowKey", "backLayoutHasName", "backLayoutHasNumber", "showErrors"],
  methods: {
    ...mapActions(["requestTextStamp"]),
    debounceName: debounce(function (e) {
      const newValue = e.target.value
          .replace(/(?!\w|\s)./g, "")
          .replace(/\s+/g, " ")
          .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2") //remove special chars and double white spaces

      this.$emit("updateRosterField", {
        rowKey: this.rowKey,
        nameValue: newValue ? newValue : "",
        numberValue: this.tempNumber,
        requestTextStamp: true
      });
    }, 1000),

    debounceNumber: debounce(function (e) {
      const newValue = e.target.value.replace(/\D/g, "") //only numbers
      this.$emit("updateRosterField", {
        rowKey: this.rowKey,
        nameValue: this.tempName,
        numberValue: newValue ? newValue : "",
        requestTextStamp: true
      });
    }, 1000),

    updateBoth() {
      this.$emit("updateRosterField", {
        rowKey: this.rowKey,
        nameValue: this.tempName,
        numberValue: this.tempNumber,
        requestTextStamp: true
      });
    },

    remove() {
      this.$emit("deleteRow", {
        key: this.rowKey
      })
    },

    focusRow() {
      this.$emit("setActiveRow", {
        key: this.rowKey
      })
    }
  },
  computed: {
    ...mapState(["roster", "selectedStyle", "relatedPrices"]),
    genderMatchId() {
      const genderBase = this.fieldGender.toLowerCase() === "men" || this.fieldGender.toLowerCase() === "youth"
        ? "men" : "women"
      return this.selectedStyle.gender_matches[genderBase]
    },
    productPrice() {
      return this.relatedPrices[this.genderMatchId][this.fieldSize.toUpperCase()] 
        ? this.relatedPrices[this.genderMatchId][this.fieldSize.toUpperCase()]
        : this.relatedPrices[this.genderMatchId].base
    },
    genders() {
      //return this.selectedStyle.genders
      return Object.keys(this.selectedStyle.gender_matches).filter((match) => {
        return this.selectedStyle.gender_matches[match] != null
      })
    },
    sizesByGender() {
      return this.productSizes[this.fieldGender];
    },
    fieldName() {
      return this.rosterRow?.name;
    },
    
    fieldQuantity: {
      get() {
        return this.rosterRow ? this.rosterRow.quantity : "";
      },
      set(value) {
        this.$emit("updateRosterField", {
          rowKey: this.rowKey,
          quantity: value,
        });
      },
    },
    fieldSize: {
      get() {
        return this.rosterRow ? this.rosterRow.size ? this.rosterRow.size : "" : ""
      },
      set(value) {
        this.$emit("updateRosterField", {
          rowKey: this.rowKey,
          size: value,
        });
      },
    },
    fieldGender: {
      get() {
        return this.rosterRow ? this.rosterRow.gender.toLowerCase() : "";
      },
      set(value) {
        //update gender
        this.$emit("updateRosterField", {
          rowKey: this.rowKey,
          gender: value.toLowerCase(),
        });
        //set first size value for new gender
        this.$emit("updateRosterField", {
          rowKey: this.rowKey,
          size: this.sizesByGender ? this.sizesByGender[0] : "",
        });
      },
    },
    totalPrice() {
      return (this.fieldQuantity * this.productPrice).toFixed(2);
    },
  },
  watch: {
    tempNumber(newValue) {
      //converts field into only numbers and updates it if changed
      this.tempNumber = newValue && newValue.length
      ? newValue.replace(/\D/g, '')
      : newValue
    },
    tempName(newValue) {
      //remove special chars and double white spaces
        this.tempName = newValue && newValue.length 
        ? newValue
          .replace(/[^a-z0-9 ]/gi, '')
        : newValue
    },
  },
  mounted() {
    this.tempNumber = this.rosterRow ? this.rosterRow.number : ""
    this.tempName = this.rosterRow ? this.rosterRow.name : ""

  },
  data() {
    return {
      tempNumber: "00",
      tempName: "Player Name",
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  .roster-row {
    display: flex;
    align-items: center;
    margin: 15px 0;
    .roster-row-column {
      padding: 0 7px;
      display: flex;
      flex-direction: column;
      text-align: center;
      input,
      select {
        height: 40px;
        box-sizing: border-box;
        padding: 3px 5%;
        font-family: "SlateStd";
        border: none;
        background: #F8F8F8 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        outline: none;
        font-size: 16px;
        color: #2E2E2E;
        text-transform: uppercase;
      }
      &.roster-actions {
        opacity: 0;
        .roster-action {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          .roster-action-label {
            font-size: 12px;
            color: #555555;
          }
        }
      }
      &.roster-row-column-name {
        width: 235px;
      }
      &.roster-row-column-number {
        width: 65px;
        input {
          text-align: center;
        }
      }
      &.roster-row-column-size {
        width: 75px;
      }
      &.roster-row-column-gender {
        width: 100px;
        select {
          text-transform: capitalize;
        }
      }
      &.roster-row-column-qty {
        width: 80px;
        input {
          text-align: center;
        }
      }
      &.roster-row-column-price {
        width: 80px;
      }
    }
    &:hover {
      .roster-actions {
        opacity: 1;
      }
    }
    &.active-row {
      input,
      select {
        box-shadow: 1px 1px 4px #004a8f;
        &.error {
          box-shadow: 1px 1px 4px #a40000;
        }
      }
    }
    input,
    select {
      &.error {
        box-shadow: 1px 1px 4px #a40000;
      }
    }
  }
}
</style>