<template>
  <div class="color-picker-wrapper" :class="{ 'color-picker-large': colorPicker.large}">
    <div class="color-picker" :style="{ backgroundColor }" @click="togglePicker">
    </div>
    <h5 class="color-picker-title">
      {{ color.colorname }}
    </h5>
    <ColorPickerList v-if="colorPicker.opened" :color="color" :colors="colors" v-on="{ selectColor }"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ColorPickerList from "@/components/Product/ColorPickerList"

export default {
  name: "ColorPicker",
  props: ["colorPicker", "loopIndex"],
  methods: {
    ...mapActions(["setField", "requestTextStamp"]),
    ...mapActions("customizations", ["initSentCustomizations"]),
    togglePicker() {
      this.$emit("togglePicker", this.loopIndex);
    },
    selectColor(color) {
      //reset roster nav
      this.initSentCustomizations()

      //Change selected field
      this.setField({
        field: this.colorPicker.field,
        value: color.id,
        parent: this.colorPicker.parent
      });

      //If selected field is c2 or c3, also change fill or outline and request their text stamps
      if (["color_2", "color_3"].indexOf(this.colorPicker.field) > -1) {
        let parents = ["teamName", "number", "playerName"]
        for (let index = 0; index < parents.length; index++) {
          const parent = parents[index];
          this.setField({
            field: this.colorPicker.field === "color_2" ? "fill" : "outline",
            value: color.id,
            parent
          })
          this.requestTextStamp({
            mainField: parent,
          });
        }
      }

      //Request text stamp if the field that changed is related to text stmaps
      if (["fill", "outline"].indexOf(this.colorPicker.field) > -1) {
        this.requestTextStamp({
          mainField: this.colorPicker.parent,
        });
      }
      this.togglePicker();
    }
  },
  computed: {
    ...mapGetters(["getColorByField"]),
    ...mapState(["colors"]),
    color() {
      return this.getColorByField({
        field: this.colorPicker.field,
        parent: this.colorPicker.parent
      });
    },
    backgroundColor() {
      return this.color.hex;
    }
  },
  components: {
    ColorPickerList
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .color-picker-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 155px;
      margin-right: 10px;
      &.color-picker-large {
        width: 290px;
      }
      @media screen and (max-width: 720px) {
        width: 290px;
        max-width: 90%;
      }
      .color-picker {
        width: 100%;
        height: 65px;
        box-sizing: border-box;
        -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
        box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.3);
        cursor: pointer;
      }
      .color-picker-title {
        margin: 10px 0;
      }
    }
  }
</style>