const menus = {
  namespaced: true,
  state: {
    colorPickers: [
      {
        field: "color_1",
        large: true,
        opened: false,
      },
      {
        field: "color_2",
        opened: false,
      },
      {
        field: "color_3",
        opened: false,
      },
    ],
    //this items are for normal layout, i also need logo and show teamname or logo depending on current layout
    customizationItems: [
      {
        field: "teamName",
        label: "team name",
        large: true,
        opened: false,
        activeSide: "front",
      },
      {
        field: "playerName",
        label: "player name",
        opened: false,
        large: true,
        activeSide: "back",
      },
      {
        field: "number",
        label: "number",
        opened: false,
        activeSide: "front",
      },
    ]
  },
  
  getters: {
  },

  actions: {
    closeAll({ commit }) {
      commit("closeAll")
    }
  },

  mutations: {
    closeAll: (state) => {
      for (let index = 0; index < state.colorPickers.length; index++) {
        state.colorPickers[index].opened = false
      }
      for (let index = 0; index < state.customizationItems.length; index++) {
        state.customizationItems[index].opened = false
      }
    }
  },
}

export default menus