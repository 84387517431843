<template>
  <transition name="fade">
    <div class="product-image" v-if="shadow">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 800 1000"
        style="enable-background: new 0 0 800 1000"
        xml:space="preserve"
        v-show="shadowloaded"
      >
        <template v-if="!isHybridSVG">
          <g>
            <path
              v-for="(path, index) in group_1"
              :key="index"
              :d="path"
              v-bind:style="{ fill: color_1 }"
            ></path>
          </g>

          <g>
            <path
              v-for="(path, index) in group_2"
              :key="index"
              :d="path"
              v-bind:style="{ fill: color_2 }"
            ></path>
          </g>

          <g>
            <path
              v-for="(path, index) in group_3"
              :key="index"
              :d="path"
              v-bind:style="{ fill: color_3 }"
            ></path>
          </g>
        </template>
        <template v-if="isHybridSVG">
          <g>
            <image
              v-if="hybridC1"
              width="800"
              height="1000"
              class="svg-background"
              :xlink:href="hybridC1"
            ></image>
          </g>
          <g>
            <image
              v-if="hybridC2"
              width="800"
              height="1000"
              class="svg-background"
              :xlink:href="hybridC2"
            ></image>
          </g>
          <g>
            <image
              v-if="hybridC3"
              width="800"
              height="1000"
              class="svg-background"
              :xlink:href="hybridC3"
            ></image>
          </g>
        </template>
        <image
          v-if="name_allowed"
          v-show="name_visible"
          class="svg-name"
          :width="stamp_name_properties ? stamp_name_properties.width : '0%'"
          :x="stamp_name_properties ? stamp_name_properties.x : '0%'"
          :y="stamp_name_properties ? stamp_name_properties.y : '0%'"
          :height="stamp_name_properties ? stamp_name_properties.height : '0%'"
          :xlink:href="stamp_name"
        ></image>
        <image
          v-if="number_allowed"
          v-show="number_visible"
          class="svg-number"
          :width="
            stamp_number_properties ? stamp_number_properties.width : '0%'
          "
          :x="stamp_number_properties ? stamp_number_properties.x : '0%'"
          :y="stamp_number_properties ? stamp_number_properties.y : '0%'"
          :height="
            stamp_number_properties ? stamp_number_properties.height : '0%'
          "
          :xlink:href="stamp_number"
        ></image>
        <GraphicLoader v-if="graphicsLoading" :px="'43%'" :py="'25%'" />
        <image
          preserveAspectRatio="xMidYMin"
          v-if="logo_allowed"
          v-show="stamp_logo"
          class="svg-logo"
          :width="stamp_logo_properties.width"
          :x="stamp_logo_properties.x"
          :y="stamp_logo_properties.y"
          :height="stamp_logo_properties.height"
          :xlink:href="stamp_logo"
        ></image>
        <image
          v-if="shadow"
          width="800"
          height="1000"
          class="svg-background"
          :xlink:href="shadow"
          @load="shadowloaded = true"
        ></image>
      </svg>
    </div>
  </transition>
</template>

<script>
const apiUrl = process.env.VUE_APP_ENDPOINT;
const s3Url =
  "https://s3.amazonaws.com/marketing.foundersportgroup.com/flash-builder/"; //8823/front/flight_c1_black.png

import { mapGetters, mapState } from "vuex";
import GraphicLoader from "@/components/GraphicLoader";

//const default_properties = { width: "0%", height: "0%", x: "0%", y: "0%" };

export default {
  name: "Vector",
  props: [
    "svgs",
    "backgrounds",
    "layout",
    "forcedColors",
    "disableStamps",
    "forcedDesign",
    "relatedGenderMatchId"
  ],
  components: { GraphicLoader },
  watch: {
    shadow() {
      this.shadowloaded = false;
    },
  },
  computed: {
    ...mapState([
      "activeGender",
      "activeSide",
      "frontMode",
      "logo",
      "selectedStyle",
      "selectedDesign",
    ]),
    ...mapState("graphics", [
      "converteditedFile",
      "graphicsLoading",
      "position",
    ]),
    ...mapState("layouts", ["layoutsProperties"]),
    ...mapGetters(["getColorHexByField", "getColorById", "getColorByField"]),

    color_1() {
      if (this.forcedColors) return this.getColorById(this.forcedColors[0]).hex;
      return this.getColorHexByField({
        field: "color_1",
      });
    },

    color_2() {
      if (this.forcedColors) return this.getColorById(this.forcedColors[1]).hex;
      return this.getColorHexByField({
        field: "color_2",
      });
    },

    color_3() {
      if (this.forcedColors) return this.getColorById(this.forcedColors[2]).hex;
      return this.getColorHexByField({
        field: "color_3",
      });
    },

    isHybridSVG() {
      return this.forcedDesign
        ? this.forcedDesign.color === "precolored"
        : this.selectedDesign?.color === "precolored";
    },

    //Returns an img url for group_1 ie https://s3.amazonaws.com/marketing.foundersportgroup.com/flash-builder/8823/back/bankshot_c1_white.png
    hybridC1() {
      const styleId = this.relatedGenderMatchId 
        ? this.relatedGenderMatchId
        : this.selectedStyle.style_id,
        
        colorName = this.forcedColors
          ? this.getColorById(this.forcedColors[0]).colorname.toLowerCase()
          : this.getColorByField({
              field: "color_1",
            })
              .colorname.toLowerCase()
              .replaceAll(" ", "-"),
        
        designName = this.forcedDesign
          ? this.forcedDesign.name?.toLowerCase()
          : this.selectedDesign?.name?.toLowerCase();

      return `${s3Url}${styleId}/${this.activeSide}/${designName}_c1_${colorName}.png`
    },

    //Returns an img url for group_2 ie https://s3.amazonaws.com/marketing.foundersportgroup.com/flash-builder/8823/back/bankshot_c2_white.png
    hybridC2() {
      const styleId = this.relatedGenderMatchId 
        ? this.relatedGenderMatchId
        : this.selectedStyle.style_id,

        colorName = this.forcedColors
          ? this.getColorById(this.forcedColors[1]).colorname.toLowerCase()
          : this.getColorByField({
              field: "color_2",
            })
              .colorname.toLowerCase()
              .replaceAll(" ", "-"),

        designName = this.forcedDesign
          ? this.forcedDesign.name?.toLowerCase()
          : this.selectedDesign?.name?.toLowerCase();

      return `${s3Url}${styleId}/${this.activeSide}/${designName}_c2_${colorName}.png`;
    },

    //Returns an img url for group_3 ie https://s3.amazonaws.com/marketing.foundersportgroup.com/flash-builder/8823/back/bankshot_c3_white.png
    hybridC3() {
      const styleId = this.relatedGenderMatchId 
        ? this.relatedGenderMatchId
        : this.selectedStyle.style_id,
        
        colorName = this.forcedColors
          ? this.getColorById(this.forcedColors[2]).colorname.toLowerCase()
          : this.getColorByField({
              field: "color_3",
            })
              .colorname.toLowerCase()
              .replaceAll(" ", "-"),

        designName = this.forcedDesign
          ? this.forcedDesign.name?.toLowerCase()
          : this.selectedDesign?.name?.toLowerCase();

      return `${s3Url}${styleId}/${this.activeSide}/${designName}_c3_${colorName}.png`;
    },

    gender_family() {
      return this.activeGender.toLowerCase() === "men" ||
        this.activeGender.toLowerCase() === "youth"
        ? "men"
        : "women";
    },
    group_1() {
      return this.svgs && this.svgs.group1 ? this.svgs.group1 : "";
    },
    group_2() {
      return this.svgs && this.svgs.group2 ? this.svgs.group2 : "";
    },
    group_3() {
      return this.svgs && this.svgs.group3 ? this.svgs.group3 : "";
    },

    shadow() {
      if (this.backgrounds) {
        let key = "shadow_" + this.gender_family + "_" + this.activeSide;
        if (this.backgrounds[key]) {
          if (this.backgrounds[key].indexOf(".com") !== -1) {
            return this.backgrounds[key]; //returns api shadow
          }
          return apiUrl + this.backgrounds[key];
        }
      }
      return false;
    },
    stamp_name() {
      return this.activeSide === "front"
        ? this.$store.state.teamName.stamp_url
        : this.$store.state.playerName.stamp_url;
    },

    stamp_number() {
      return this.$store.state.number.stamp_url;
    },

    stamp_logo() {
      return this.converteditedFile ? this.converteditedFile : this.logo.url;
    },

    filteredLayoutProperties() {
      //layoutsProperties
      if (!this.layout) return false
      const styleId = this.relatedGenderMatchId 
        ? this.relatedGenderMatchId
        : this.selectedStyle.style_id

      const overrideLayout = this.layoutsProperties.find((layout) => {
        return layout.styleId.toString() === styleId.toString()
      })

      if (!overrideLayout) return this.layout

      if (this.activeSide === 'front') {
        return overrideLayout.layouts.front.find((layoutSide) => {
          return layoutSide.code === this.layout.code
        })?.properties
      }
      else {
        return overrideLayout.layouts.back[0].properties
      }

    },

    stamp_name_properties() {
      return this.name_allowed ? this.filteredLayoutProperties.name : {};
    },

    stamp_number_properties() {
      return this.number_allowed ? this.filteredLayoutProperties.number : {};
    },

    stamp_logo_properties() {
      return this.logo_allowed ? this.filteredLayoutProperties.graphic : {};
      //return { width: "35%", x: "32.5%", y: "20%", height: "28%" }
    },

    isFrontAndGraphicMode() {
      return this.activeSide === "front" && this.frontMode === "graphic";
    },
    name_allowed() {
      return !this.disableStamps && this.layout && this.layout.name != null;
      //return !this.disableStamps && !this.isFrontAndGraphicMode;
    },
    number_allowed() {
      return !this.disableStamps && this.layout && this.layout.number != null;
      //return !this.disableStamps && !this.isFrontAndGraphicMode;
    },
    logo_allowed() {
      return (
        !this.graphicsLoading && this.layout && this.layout.graphic != null
      );
      //return !this.graphicsLoading && this.isFrontAndGraphicMode
    },
    name_visible() {
      return true; //todo check if this app will allow hide certain fields
    },
    number_visible() {
      return true; //todo check if this app will allow hide certain fields
    },
    yPosition() {
      //returns align-items: $value
      return [1, 2, 3].indexOf(this.position) >= 0
        ? "YMin"
        : [4, 5, 6].indexOf(this.position) >= 0
        ? "YMid"
        : "YMax";
    },
    xPosition() {
      return [1, 4, 7].indexOf(this.position) >= 0
        ? "xMin"
        : [2, 5, 8].indexOf(this.position) >= 0
        ? "xMid"
        : "xMax";
    },
    graphicPosition() {
      return this.xPosition + this.yPosition;
    },
  },
  data() {
    return {
      resizeOffset: 0,
      shadowloaded: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
svg {
  max-height: 80vh;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>