<template>
  <div class="layout-card" @click="selectLayout" :title="layout.label">
    <div class="layout-vector">
      <Vector
        :svgs="svgs"
        :backgrounds="backgrounds"
        :side="activeSide"
        :gender="activeGender"
        :layout="layout"
      />
    </div>
    <div class="layout-name">{{ layout.label }}</div>
  </div>
</template>

<script>
import Vector from "@/components/Vector.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "TheLayoutCard",
  props: ["layout"],
  computed: {
    ...mapState([
      "selectedStyle",
      "activeSide",
      "activeGender",
      "selectedDesign",
    ]),
    ...mapState("layouts", ["activeFrontLayout", "activeBackLayout"]),
    svgs() {
      return this.selectedDesign && this.selectedDesign.svg_data
        ? this.selectedDesign.svg_data[this.activeSide]
        : [];
    },
    backgrounds() {
      return this.selectedStyle.backgrounds;
    },
  },
  methods: {
    ...mapActions("layouts", ["setActiveBackLayout", "setActiveFrontLayout"]),
    ...mapActions("navigation", ["navigate"]),
    ...mapActions(["setFrontSide", "setBackSide"]),
    ...mapActions("customizations", ["applyDesign", "initSentCustomizations"]),
    selectLayout() {
      this.initSentCustomizations();
      if (this.activeSide === "front") {
        this.setActiveFrontLayout(this.layout);
        if (this.activeBackLayout == null) {
          this.setBackSide();
        } else {
          this.nextStep();
        }
      } else {
        this.setActiveBackLayout(this.layout);
        if (this.activeFrontLayout == null) {
          this.setFrontSide();
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      this.applyDesign({
        layout: this.activeFrontLayout,
      });
      this.navigate({
        destination: "TheCustomizePage",
      });
    },
  },
  components: {
    Vector,
  },
};
</script>

<style lang="scss" scoped>
#app {
  .layout-card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 225px;
    height: 320px;
    padding: 5px;
    margin: 5px 25px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    z-index: 1;
    background-color: white;
    .layout-vector {
      padding: 10px;
      min-height: 265px;
    }
    .layout-name {
      text-align: center;
      font-family: "Forza";
      font-size: 18px;
    }
    &:hover {
      -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 1);
      -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 1);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 1);
    }
  }
}
</style>