<template>
  <div class="roster-uploader-container">
    <div class="roster-uploader-item">
      <a target="_blank" :href="templateURL" title="Download Roster Template" class="roster-uploader-item-title"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"><path d="M19.35,10.04a7.492,7.492,0,0,0-14-2A6,6,0,0,0,6,20H19a4.986,4.986,0,0,0,.35-9.96ZM14,13v4H10V13H7l5-5,5,5Z" transform="translate(0 -4)" style="fill: rgb(51,102,153);"/></svg> Download Roster Template </a>
    </div>
    <div class="roster-uploader-item">
      <label for="upload-roster-input" class="roster-uploader-item-title"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"><path d="M19.35,10.04a7.492,7.492,0,0,0-14-2A6,6,0,0,0,6,20H19a4.986,4.986,0,0,0,.35-9.96ZM14,13v4H10V13H7l5-5,5,5Z" transform="translate(0 -4)" style="fill: rgb(51,102,153);"/></svg> Upload Roster File </label>
      <input
        class="hidden"
        type="file"
        accept=".xlsx"
        ref="rosterUpload"
        id="upload-roster-input"
        @change="fileChanged"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: "TheRosterUploader",
  computed: {
    ...mapState(["selectedStyle"]),
    templateType() {
      return this.selectedStyle.genders.length === 4 ? "All" : "Men_Women"
    },
    templateURL() {
      return `/templates/RosterTemplate_${this.templateType}.xlsx`
    }
  },
  methods: {
    ...mapActions(["uploadRoster"]),
    fileChanged() {
      this.selectedFile = this.$refs.rosterUpload.files[0]
      this.uploadRoster({
        file: this.selectedFile
      })
    }
  },
  data() {
    return {
      selectedFile: null,
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .roster-uploader-container {
      display: flex;
      justify-content: center;
      padding: 5px;
      margin: 20px;
      .roster-uploader-item {
        margin: 0 10px;
        padding: 7px 14px;
        background-color: #ececec;
        display: flex;
        align-items: center;
        cursor: pointer;
        .roster-uploader-item-title {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: rgb(51,102,153);
          font-size: 0.8rem;
          cursor: pointer;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>