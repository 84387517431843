<template>
    <div class="page colors-page">
      <div class="colors-page-title">
        Customize design colors
      </div>
      <div class="colors-page-description">
        <p>We will use these colors to help you visualize and find the perfect design.</p>
        <p>Don't worry, you can always adjust these later on in the process too.</p>
      </div>
      <div class="color-pickers-container">
        <ColorPicker
          v-for="(colorPicker, index) in colorPickers"
          v-on="{ togglePicker }"
          :key="index"
          :loopIndex="index"
          :colorPicker="colorPicker"
        />
      </div>
      <div class="colors-page-actions">
        <div class="colors-page-action" @click="continueAction">
          Continue
        </div>
      </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ColorPicker from "@/components/ColorPicker"
export default {
  name: "TheColorsPage",
  computed: {
    ...mapState("menus", ["colorPickers"]),
  },
  methods: {
    ...mapActions("navigation", ["setNavigationItems", "navigate"]),
    continueAction() {
      this.navigate({
        destination: "TheDesignPage"
      })
    },
    togglePicker(key) {
      for (let index = 0; index < this.colorPickers.length; index++) {
        if (index !== key) {
          this.colorPickers[index].opened = false;
        }
      }
      this.colorPickers[key].opened = !this.colorPickers[key].opened;
    },
  },
  mounted() {
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
  },
  data() {
    return {
      navigationItems: ["Gender", "Style", "Colors"],
    };
  },
  components: {
    ColorPicker
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .colors-page {
      width: 865px;
      max-width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      padding: 20px;
      .colors-page-title {
        font-family: "Forza";
        text-transform: uppercase;
        font-size: 26px;
      }
      .colors-page-description {
        margin: 25px;
        p {
          font-size: 16px;
          margin: 3px;
          text-align: center;
        }
      }
      .color-pickers-container {
        display: flex;
        @media screen and (max-width: 720px) {
          flex-direction: column;
          align-items: center;
        }
      }
      .colors-page-actions {
        margin: 25px 0;
        .colors-page-action {
          width: 300px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background-color: black;
          font-size: 24px;
          text-transform: uppercase;
          border: 1px solid #000000;
          border-radius: 15px;
          cursor: pointer;
        }
      }
    }
  }
</style>