import router from '../../router'

const navigation = {
  namespaced: true,
  state: {
    enabledNavigationItems: [],
    navigationItems: [
      "Gender",
      "Style",
      "Colors",
      "Design",
      "Layout",
      "Customize",
      "Roster",
    ]
  },
  actions: {
    navigate(e, payload) {
      router.push({ name: payload.destination });
    },
    setNavigationItems({ commit }, payload) {
      commit("setNavigationItems", payload)
    }
  },
  mutations: {
    setNavigationItems: (state, payload) => {
      state.enabledNavigationItems = payload.navigationItems
    }
  },
}

export default navigation