<template>
  <div class="roster">
    <div class="roster-rows-header">
      <div
        v-for="(column, index) in filteredColumns"
        :key="index"
        class="roster-row-header-column"
        :class="'roster-row-header-column-' + column"
      >
        {{ column }}
      </div>
    </div>
    <div class="roster-rows-body">
      <RosterRow
        v-for="rosterRow in rosterRows"
        :key="rosterRow.key"
        :rowKey="rosterRow.key"
        :productSizes="selectedStyle.sizes"
        :designGender="designGender"
        :rosterRow="rosterRow"
        :activeRowKey="activeRowKey"
        :backLayoutHasName="backLayoutHasName"
        :backLayoutHasNumber="backLayoutHasNumber"
        :showErrors="showErrors"
        v-on="{ deleteRow, updateRosterField, setActiveRow }"
      />
    </div>
  </div>
</template>

<script>
import RosterRow from "@/components/Product/RosterRow";
import { mapState } from "vuex";

export default {
  name: "RosterRows",
  props: ["rosterRows", "activeRowKey", "showErrors"],
  computed: {
    ...mapState(["selectedStyle", "selectedDesign", "roster"]),
    ...mapState("layouts", ["activeBackLayout"]),
    filteredColumns() {
      return this.columns.filter((column) => {
        return (!(column === "name" && !this.backLayoutHasName)) && (!(column === "number" && !this.backLayoutHasNumber))
      })
    },
    backLayoutHasName() {
      return this.activeBackLayout.name != null
    },
    backLayoutHasNumber() {
      return this.activeBackLayout.number != null
    },
    productPrice() {
      //todo price by gender???
      return parseFloat(this.selectedStyle.price);
    },
    designGender() {
      return this.selectedDesign.gender;
    },
  },
  methods: {
    updateRosterField(payload) {
      this.$emit("updateRosterField", payload);
    },
    deleteRow(payload) {
      this.$emit("deleteRow", payload);
    },
    setActiveRow(payload) {
      this.$emit("setActiveRow", payload);
    },
  },
  components: {
    RosterRow,
  },
  mounted() {
    this.rosterRows = this.roster;
  },
  data() {
    return {
      columns: ["name", "number", "gender", "size", "qty", "price"],
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  .roster {
    .roster-rows-header {
      display: flex;
      font-family: "Forza";
      text-transform: uppercase;
      color: #777777;
      .roster-row-header-column {
        box-sizing: border-box;
        font-size: 14px;
        text-align: center;
        font-weight: bold;
      }
      .roster-row-header-column-name {
        width: 235px;
      }
      .roster-row-header-column-number {
        width: 65px;
      }
      .roster-row-header-column-size {
        width: 75px;
      }
      .roster-row-header-column-gender {
        width: 100px;
      }
      .roster-row-header-column-qty {
        width: 80px;
      }
      .roster-row-header-column-price {
        width: 80px;
      }
    }
  }
}
</style>