<template>
  <div class="page gender-page">
    <div class="gender-boxes-container">
      <TheGenderBox v-for="(box, index) in genderBoxes" :key="index" :box="box" />
    </div>
    <TheSavedDesign />
    <TheProductBranding />
    <div class="design-examples-container">
      <div class="design-examples-title">Perfect for Corporate, Events or Team Uniforms</div>
      <div class="design-examples">
        <img class="design-example" :src="`/images/HOME${(index)}.png`" v-for="index in 6" :key="index">  
      </div>
    </div>
  </div>
</template>

<script>
import TheGenderBox from "@/components/TheGenderPage/TheGenderBox"
import TheSavedDesign from "@/components/TheSavedDesign"
import TheProductBranding from "@/components/TheProductBranding"
import { mapActions } from "vuex";
export default {
  name: "TheGenderPage",
  methods: {
    ...mapActions("navigation", ["setNavigationItems"]),
    ...mapActions(["resetField"])
  },
  mounted() {
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
  },
  data() {
    return {
      genderBoxes: [
        {
          genderName: "men",
          title: "Shop Men's/Boy's",
          description: "Available in S/S, Sleeveless, and Tank",
          images: ["home-flash-men.png"],
        },
        {
          genderName: "women",
          title: "Shop Women's/Girl's",
          description: "Available in S/S, Sleeveless, and Tank",
          images: ["home-flash-women.png"],
        },
      ],
      navigationItems: ["Gender"],
    };
  },
  components: {
    TheGenderBox,
    TheProductBranding,
    TheSavedDesign
  },
};
</script>

<style lang="scss" scoped>
#app {
  .gender-page {
    display: flex;
    flex-direction: column;
    .gender-boxes-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    
    .design-examples-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .design-examples-title {
        margin-bottom: 2em;
        font-weight: bold;
      }
      .design-examples {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .design-example {
        max-height: 15em;
      }
    }
  }
}
</style>