<template>
  <div class="graphic-customization-container">
    <TheGraphicsModal v-if="modalOpened" />
    <div class="graphic-preview" @click="triggerGraphicInput" v-if="!graphicsLoading">
      <img class="graphic-preview-img" :src="logoPreviewUrl">
      <input id="graphic-file-input" class="hidden" type="file" ref="graphicFile" @change="graphicInputChanged">
    </div>
    <div class="graphic-preview graphic-preview-loading" v-else>
      <Loader/>
    </div>
    <div class="graphic-information">
      <p class="grahic-information-item" v-for="(text, index) in graphicTexts" :key="index">
        <span v-for="(row, rowIndex) in text.rows" :key="rowIndex">
          {{ row }}
        </span>
      </p>
      <p class="grahic-information-item error">
        <span v-if="error">The graphic field is required</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loader from "@/components/Loader"
import TheGraphicsModal from "@/components/TheGraphicsModal"
export default {
  name: "GraphicCustomization",

  computed: {
    ...mapState(["activeSide", "logo"]),
    ...mapState("graphics", ["converteditedFile", "graphicsLoading", "modalOpened"]),
    logoPreviewUrl() {
      return this.converteditedFile ? this.converteditedFile : this.logo.url;
    }
  },
  methods: {
    ...mapActions(["setField", "setFrontSide"]),
    ...mapActions("graphics", ["triggerModal", "uploadGraphic", "requestLogos", "applyCustomizations", "saveDealerCustomizations", "closeSession"]),
    triggerGraphicInput() {
      //remove current graphic in case user chooses de same file????
//      document.getElementById("graphic-file-input").click()
      this.setFrontSide()
      this.triggerModal(true)
      /*this.requestLogos({
        email: "test@test.com"
      })*/
      //this.applyCustomizations()
      //this.saveDealerCustomizations()
      //this.closeSession()
    },
    startLogoUpload() {
      this.uploadGraphic({
        file: this.$refs.graphicFile.files[0]
      })
    },
    graphicInputChanged() {
      this.startLogoUpload()
    },
    
  },
  data() {
    return {
      graphicTexts: [
        {
          rows: [
            "Click the box to the left to add your own graphic"
          ]
        },
        { 
          rows: [
            "Accepted File Types:",
            "AI, BPM, JPEG, PDF, PNG, PSD, TIFF"
          ]
        },
        { 
          rows: [
            "Recommended Size Requirements",
            "150 dpi, 2100 x 2100 pixels"
          ]
        },
      ]
    }
  },
  components: {
    Loader, 
    TheGraphicsModal
  },
  props: ["error"]
}
</script>

<style lang="scss" scoped>
  #app {
    .product-customization-team {
      .graphic-customization-container {
        display: flex;
        position: relative;
        .graphic-preview {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: 260px;
          height: 260px;
          border: 1px solid #000;
          margin-right: 20px;
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
          cursor: pointer;
          &.graphic-preview-loading {
            cursor: wait;
          }
          box-sizing: border-box;
          .graphic-preview-img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .graphic-information {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .grahic-information-item {
            display: flex;
            flex-direction: column;
            color: #868686;
            @media screen and (max-width: 720px) {
              display: none;
            }
            &.error {
              color: red;
            }
          }
        }
      }
      &.error {
        .graphic-customization-container {
          .graphic-preview {
            border-color: red;
          }
        }
      }
    }
  }
</style>