<template>
  <div class="product-customization">
    <div class="product-customization-control">
      <div class="product-customization-control-title">
        Customize design colors
      </div>
      <div class="product-customization-main-color-pickers">
        <ColorPicker
          v-for="(colorPicker, index) in colorPickers"
          v-on="{ togglePicker }"
          :key="index"
          :loopIndex="index"
          :colorPicker="colorPicker"
        />
      </div>
    </div>
    <div
      class="product-customization-control product-customization-control-secondary"
    >
      <div class="product-customization-control-title" v-if="layoutHasFrontCustomizations">
        <span class="product-customization-control-title">Customize the front</span>
        <span class="product-customization-control-title error" v-if="teamNameMissing && showMissingInfo">Team name must be changed before continuing.</span>
      </div>
      <div class="product-customization-team" v-if="frontLayoutHasName">
        <TeamCustomizationItem
          v-for="(customizationItem, itemIndex) in frontCustomizations"
          v-on="{ toggleCustomizationItem }"
          :key="itemIndex"
          :loopIndex="itemIndex"
          :customizationItem="customizationItem"
          :side="'front'"
          :teamNameMissing="teamNameMissing && showMissingInfo"
        />
      </div>
      <div class="product-customization-team" :class="{ error: graphicMissing && showMissingInfo }" v-else-if="frontLayoutHasGraphic">
        <GraphicCustomization :error="graphicMissing && showMissingInfo" />
      </div>
    </div>
    <div
      class="product-customization-control product-customization-control-secondary" v-if="layoutHasBackCustomizations"
    >
      <div class="product-customization-control-title">Customize the back</div>
      <div class="product-customization-team">
        <TeamCustomizationItem
          v-for="(customizationItem, itemIndex) in backCustomizations"
          v-on="{ toggleCustomizationItem }"
          :key="itemIndex"
          :loopIndex="itemIndex"
          :customizationItem="customizationItem"
          :side="'back'"
        />
      </div>
    </div>
    <div class="customization-actions">
      <div class="customization-action-wrapper" @click="nextStep" :class="{ disabled: graphicMissing }">
        <span class="customization-action"> Continue to sizes/roster </span>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker";
import TeamCustomizationItem from "@/components/Product/TeamCustomizationItem";
import GraphicCustomization from "@/components/Product/GraphicCustomization";
import { mapActions, mapState } from "vuex";

export default {
  name: "Customizer",
  components: {
    ColorPicker,
    TeamCustomizationItem,
    GraphicCustomization,
  },
  computed: {
    ...mapState(["roster", "selectedStyle", "teamName"]),
    ...mapState("menus", ["colorPickers", "customizationItems"]),
    ...mapState("layouts", ["activeFrontLayout", "activeBackLayout"]),
    ...mapState("graphics", ["originalFile"]),
    graphicRequired() {
      return this.activeFrontLayout.code === "front-custom-graphic" || this.activeFrontLayout.code === "left-custom-graphic"
    },
    graphicMissing() {
      return this.graphicRequired && !this.originalFile?.length
    },
    teamNameRequired() {
      return !this.graphicRequired
    },
    teamNameMissing() {
      return this.teamNameRequired && this.teamName.value.toLowerCase() === 'team name'
    },
    genders() {
      return this.selectedStyle.genders;
    },
    gender() {
      return this.genders[0].toLowerCase();
    },
    sizes() {
      return this.selectedStyle.sizes[this.gender];
    },
    textFrontMode() {
      return this.frontMode === "text";
    },
    frontMode: {
      get() {
        return this.$store.state.frontMode;
      },
      set(value) {
        this.setField({
          field: "frontMode",
          value,
        });
      },
    },
    /*
    // Front Layout
    */
    frontLayoutHasName() {
      return this.activeFrontLayout.name != null
    },
    frontLayoutHasNumber() {
      return this.activeFrontLayout.number != null
    },
    frontLayoutHasGraphic() {
      return this.activeFrontLayout.graphic != null
    },
    
    /*
    // Back Layout
    */
    backLayoutHasName() {
      return this.activeBackLayout.name != null
    },
    backLayoutHasNumber() {
      return this.activeBackLayout.number != null
    },
    backLayoutHasGraphic() {
      return this.activeBackLayout.graphic != null
    },
    /*
    // layouts
    */
    layoutHasFrontCustomizations() {
      return this.frontLayoutHasNumber || this.frontLayoutHasName || this.frontLayoutHasGraphic
    },
    layoutHasBackCustomizations() {
      return this.backLayoutHasNumber || this.backLayoutHasName || this.backLayoutHasGraphic
    },
    /*
    // End layouts
    */
    frontCustomizations() {

      return this.customizationItems.filter((item) => {
        return this.availableFields.front.indexOf(item.field) > -1;
      });
    },
    backCustomizations() {
      return this.customizationItems.filter((item) => {
        return this.availableFields.back.indexOf(item.field) > -1;
      });
    },
  },
  methods: {
    ...mapActions(["requestRosterStamp", "setField"]),
    ...mapActions("customizations", ["applyCustomizations", "initSentCustomizations"]),
    ...mapActions("navigation", ["navigate"]),
    setAvailableFields() {
      this.availableFields.front = []
      this.availableFields.back = []
      if (this.frontLayoutHasName) {
        this.availableFields.front.push('teamName')
      }
      if (this.frontLayoutHasNumber) {
        this.availableFields.front.push('number')
      }
      if (this.backLayoutHasNumber) {
        this.availableFields.back.push('number')
      }
      if (this.backLayoutHasName) {
        this.availableFields.back.push('playerName')
      }
    },
    initRoster() {
      if (!this.roster.length) {
        this.setField({
          field: "roster",
          value: [
            {
              key: 0,
              name: "Player Name",
              number: "00",
              //gender: this.genders[0],
              //size: this.sizes[0],
              gender: this.selectedStyle.genders[0] ? this.selectedStyle.genders[0] : "Men",
              size: "",
              quantity: "",
            },
          ],
        });
      } else {
        //update current gender based on first roster player
       /* this.setField({
          field: "activeGender",
          value: this.roster[0].gender.toLowerCase(),
        });*/
      }
      this.requestRosterStamp({
        playerName: this.roster[0].name,
        number: this.roster[0].number,
      });
    },
    nextStep() {
      //apply customizations to API
      if (!this.graphicMissing && !this.teamNameMissing) {
        this.applyCustomizations()
        this.navigate({
          destination: "TheRosterPage"
        })
      }
      else {
        this.showMissingInfo = true
      }
    },
    turnOffPickers() {
      for (let index = 0; index < this.colorPickers.length; index++) {
        this.colorPickers[index].opened = false;
      }
    },
    turnOffCustomizationItems() {
      for (let index = 0; index < this.customizationItems.length; index++) {
        this.customizationItems[index].opened = false;
      }
    },
    togglePicker(key) {
      //hide other opened pickers
      this.turnOffCustomizationItems();
      for (let index = 0; index < this.colorPickers.length; index++) {
        if (index !== key) {
          this.colorPickers[index].opened = false;
        }
      }
      this.colorPickers[key].opened = !this.colorPickers[key].opened;
    },
    toggleCustomizationItem(payload) {
      this.turnOffPickers();
      let fieldKey = this.customizationItems.findIndex((item) => {
        return item.field === payload.field;
      });
      for (let index = 0; index < this.customizationItems.length; index++) {
        if (this.customizationItems[index].field !== payload.field) {
          this.customizationItems[index].opened = false;
        }
      }
      this.customizationItems[fieldKey].opened = !this.customizationItems[
        fieldKey
      ].opened;
      this.customizationItems[fieldKey].activeSide = payload.side;
      this.setSide(payload.side);
    },
    setSide(side) {
      this.setField({
        field: "activeSide",
        value: side,
      });
    },
  },
  mounted() {
    this.initRoster()
    this.setAvailableFields()
  },
  data() {
    return {
      showGraphicsModal: false,
      showMissingInfo: false,
      availableFields: {
        front: [],
        back: []
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  .product-customization {
    position: relative;
    width: 60%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000040;
    padding: 50px 0 50px 25px;
    .product-customization-control {
      padding: 0 25px;
      display: flex;
      flex-direction: column;
      .product-customization-control-title {
        display: flex;
        align-items: baseline;
        font-family: "Forza";
        margin: 0 0 5px 0;
        text-transform: uppercase;
        font-size: 21px;
        .error {
          font-size: 16px;
          text-transform: capitalize;
          color: red;
        }
        span {
          margin-right: 20px;
        }
        .product-customization-control-options {
          display: flex;
          .product-customization-control-option {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            font-family: "SlateStd";
            color: #868686;
            font-size: 15px;
            margin-right: 5px;
            cursor: pointer;
            input {
              margin: 0 10px;
            }
            label {
              text-transform: capitalize;
              cursor: pointer;
            }
          }
        }
      }
      .product-customization-main-color-pickers {
        display: flex;
        margin: 10px 0 20px;
      }
      .product-customization-team {
        display: flex;
        margin: 10px 0 20px;
      }
    }
    &.product-customization-control-secondary {
      .product-customization-team {
        width: 80%;
      }
    }
    @media screen and (max-width: 780px) {
      max-width: 515px;
      width: 90%;
    }
  }
  .customization-actions {
    margin-top: 20px;
    display: flex;
    //background-color: #000000;
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
    justify-content: center;
    .customization-action-wrapper {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-family: "SlateStd-Bold";
      text-transform: uppercase;
      font-size: 18px;
      cursor: pointer;
      background: #000000 0% 0% no-repeat padding-box;
      border: 1px solid #000000;
      border-radius: 15px;
      .customization-action {
        padding: 15px 0;
        text-align: center;
      }
      &.disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }
}
</style>