<template>
  <div class="color-swatch-wrapper" :class="{ active: isActive }" :title="title" @click="selectColor">
    <div class="color-swatch" :style="{ backgroundColor }" :class="'color-swatch-' + color.colorname.toLowerCase()">
    </div>
    <div class="color-swatch-name">
      {{ color.colorname }}
      <img src="/images/check.svg" class="color-watch-check" v-if="isActive">
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorPickerSwatch",
  props: ["color", "activeColor"],
  computed: {
    backgroundColor() {
      return this.color.hex
    },
    isActive() {
      return this.color.id === this.activeColor.id
    },
    title() {
      return this.isActive ? "" : "Select " + this.color.colorname;
    }
  },
  methods: {
    selectColor() {
      this.$emit("selectColor", this.color)
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .color-swatch-wrapper {
      display: flex;
      align-items: center;
      margin: 5px 0;
      cursor: pointer;
      .color-swatch {
        height: 25px;
        width: 80px;
        margin-right: 10px;
        box-sizing: border-box;
        &.color-swatch-white {
          border: 1px solid #ccc;
        }
        @media screen and (max-width: 780px) {
          width: 45px;
          margin: 0;
        }
      }
      @media screen and (max-width: 780px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .color-swatch-name {
        font-family: "SlateStd";
        display: flex;
        align-items: center;
        font-size: 13px;
        width: 105px;
        @media screen and (max-width: 780px) {
          width: auto;
          display: none;
        }
      }
      &.active {
        @media screen and (max-width: 780px) {
          outline: black dashed 2px;
        }
        .color-swatch-name {
          font-weight: bold;
          .color-watch-check {
            margin-left: 8px;
            height: 14px;
            @media screen and (max-width: 780px) {
              display: none;
            }
          }
        }
      }
    }
  }
</style>