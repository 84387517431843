<template>
  <div class="debug-bar">
    <div class="debug-content">
      <div class="debug-content-title" :class="{ error: debug.error }">
        {{ debug.title }}
      </div>
      <div class="debug-content-body">
        {{ debug.content }}
      </div>
    </div>
    <!--<div class="debug-editor">
      <div class="editor-item">
        <label>Session hash</label>
        <input type="text" v-model="sessionCode">
      </div>
      <div class="editor-item">
        <label>Product Order ID</label>
        <input type="text" v-model="poiCode">
      </div>
    </div>-->
    <div class="close-bar" @click="closeBar" title="Close Debug bar">x</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: "TheDebugBar",
  computed: {
    ...mapState("customizations", ["debug", "session", "productOrderId"]),
    sessionCode: {
      get() {
        return this.session
      },
      set(newValue) {
        this.setField({
          field: "session",
          value: newValue
        })
      }
    },
    poiCode: {
      get() {
        return this.productOrderId
      },
      set(newValue) {
        this.setField({
          field: "productOrderId",
          value: newValue
        })
      }
    }
  },
  methods: {
    ...mapActions("customizations", ["setField"]),
    closeBar() {
      this.setField({
        field: "debugEnabled",
        value: false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .debug-bar {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 3px;
      background: white;
      z-index: 10;
      border-top: 1px solid;
      overflow-y: scroll;
      line-break: anywhere;
      height: 80px;
      flex-direction: column;
      .close-bar {
        position: absolute;
        top: 5px;
        right: 10px;
        color: white;
        background-color: black;
        padding: 0 5px;
        cursor: pointer;
      }
      .debug-title {
        width: 300px;  
        margin-right: 5px;
      }
      .debug-content {
        display: flex;
        flex-direction: column;
        .debug-content-title {
          font-weight: bold;
          color: green;
          &.error {
            color: red;
          }
        }
      }
    }
  }
</style>