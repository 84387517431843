const EMPTY_SVG_GROUPS = { group1: [], group2: [], group3: [] };
const BACKUP_STOCK_COLOR_IDS = [25, 23, 24];
const LAYER = {
  SIDES: ["front", "side", "back"],
  MAIN_SIDES: ["front", "side"], //sets team name front for most of jerseys and side for masks
  FIELDS: ["name", "number", "logo"], //artwork?
  MAIN_FIELDS: ["name", "number"],
};
const COLORS = [
  { id: 90015, taacolorid: 90015, hex: "#e365a6", rgb: "227,101,166", cmyk: "15,61,2,0", colorname: "Pink" },
  { id: 90039, taacolorid: 90039, hex: "#e15295", rgb: "225,82,149", cmyk: "11,80,0,6", colorname: "Electric Bubblegum" },
  { id: 90036, taacolorid: 90036, hex: "#de2776", rgb: "222,39,118", cmyk: "0,98,16,0", colorname: "Electric Pink" },
  { id: 90031, taacolorid: 90031, hex: "#e40a1d", rgb: "228,10,29", cmyk: "0,97,85,0", colorname: "USMC Red" },
  { id: 90018, taacolorid: 90018, hex: "#b9121d", rgb: "185,18,29", cmyk: "2,100,73,31", colorname: "Scarlet" },
  { id: 90003, taacolorid: 90003, hex: "#711a27", rgb: "113,26,39", cmyk: "6,99,69,64", colorname: "Cardinal" },
  { id: 90011, taacolorid: 90011, hex: "#501417", rgb: "80,20,23", cmyk: "39,98,26,71", colorname: "Maroon" },
  { id: 90033, taacolorid: 90033, hex: "#32201b", rgb: "50,32,27", cmyk: "32,64,59,81", colorname: "Brown" },
  { id: 90026, taacolorid: 90026, hex: "#563719", rgb: "86,55,25", cmyk: "4,75,100,70", colorname: "Coyote Brown" },
  { id: 90022, taacolorid: 90022, hex: "#af6317", rgb: "175,99,23", cmyk: "8,78,99,35", colorname: "Texas Orange" },
  { id: 90013, taacolorid: 90013, hex: "#cf9128", rgb: "207,145,40", cmyk: "31,49,100,4", colorname: "Old Gold" },

  { id: 90014, taacolorid: 90014, hex: "#f45c08", rgb: "244,92,8", cmyk: "0,82,98,0", colorname: "Orange" },
  { id: 90001, taacolorid: 90001, hex: "#f4901b", rgb: "244,144,27", cmyk: "4,64,99,0", colorname: "Arena Orange" },
  { id: 90021, taacolorid: 90021, hex: "#faa227", rgb: "250,162,39", cmyk: "5,46,96,0", colorname: "Tennessee Orange" },
  { id: 90007, taacolorid: 90007, hex: "#f2b83a", rgb: "242,184,58", cmyk: "5,36,90,0", colorname: "Green Bay Gold" },
  { id: 90023, taacolorid: 90023, hex: "#d6bd6c", rgb: "214,189,108", cmyk: "29,35,56,0", colorname: "Vegas Gold" },
  { id: 90010, taacolorid: 90010, hex: "#f6cd0b", rgb: "246,205,11", cmyk: "7,21,89,0", colorname: "Light Gold" },
  { id: 90030, taacolorid: 90030, hex: "#ffd400", rgb: "255,212,0", cmyk: "2,20,100,0", colorname: "USMC Gold" },
  { id: 90043, taacolorid: 90043, hex: "#ffea31", rgb: "255,234,49", cmyk: "2,0,82,0", colorname: "Turbo Yellow"},
  { id: 90041, taacolorid: 90041, hex: "#dde234", rgb: "221,226,52", cmyk: "17,0,93,0", colorname: "Electric Yellow" },
  { id: 90040, taacolorid: 90040, hex: "#c1d026", rgb: "193,208,38", cmyk: "40,0,98,0", colorname: "Electric Green" },
  { id: 90042, taacolorid: 90042, hex: "#56bcaa", rgb: "86,188,170", cmyk: "77,0,62,2", colorname: "Miami Teal" },

  { id: 90020, taacolorid: 90020, hex: "#187269", rgb: "24,114,105", cmyk: "90,0,66,8", colorname: "Teal" },
  { id: 90009, taacolorid: 90009, hex: "#396c27", rgb: "57,108,39", cmyk: "86,2,98,10", colorname: "Kelly Green" },
  { id: 90006, taacolorid: 90006, hex: "#0f2b03", rgb: "15,43,3", cmyk: "81,5,89,71", colorname: "Dark Green" },
  { id: 90034, taacolorid: 90034, hex: "#152a40", rgb: "21,42,64", cmyk: "77,62,18,75", colorname: "Storm Blue" },
  { id: 90012, taacolorid: 90012, hex: "#10103a", rgb: "16,16,58", cmyk: "93,71,2,73", colorname: "Navy Blue" },
  { id: 90032, taacolorid: 90032, hex: "#0f2556", rgb: "15,37,86", cmyk: "95,79,0,64", colorname: "USN Navy" },
  { id: 90017, taacolorid: 90017, hex: "#21368c", rgb: "33,54,140", cmyk: "95,63,0,6", colorname: "Royal Blue" },
  { id: 90029, taacolorid: 90029, hex: "#0b51d4", rgb: "11,81,212", cmyk: "95,63,3,14", colorname: "USAF Royal" },
  { id: 90037, taacolorid: 90037, hex: "#006bb1", rgb: "0,107,177", cmyk: "86,5,9,0", colorname: "Electric Aqua" },
  { id: 90005, taacolorid: 90005, hex: "#7da1d4", rgb: "125,161,212", cmyk: "56,20,2,0", colorname: "Columbia Blue" },
  { id: 90038, taacolorid: 90038, hex: "#1fbce4", rgb: "31,188,228", cmyk: "71,2,15,0", colorname: "Electric Blue" },

  { id: 90016, taacolorid: 90016, hex: "#5e1d78", rgb: "94,29,120", cmyk: "83,88,0,26", colorname: "Purple" },
  { id: 90002, taacolorid: 90002, hex: "#202020", rgb: "32,32,32", cmyk: "79,0,25,100", colorname: "Black" },
  { id: 90028, taacolorid: 90028, hex: "#000000", rgb: "0,0,0", cmyk: "79,0,25,100", colorname: "US Army Black" },
  { id: 90004, taacolorid: 90004, hex: "#606160", rgb: "96,97,96", cmyk: "58,57,55,73", colorname: "Charcoal" },
  { id: 90019, taacolorid: 90019, hex: "#7a7774", rgb: "122,119,116", cmyk: "50,47,50,15", colorname: "Silver" },
  { id: 90025, taacolorid: 90025, hex: "#7f7f74", rgb: "127,127,116", cmyk: "51,45,48,57", colorname: "Combat Grey" },
  { id: 90008, taacolorid: 90008, hex: "#c6c6c6", rgb: "198,198,198", cmyk: "30,25,26,0", colorname: "Grey" },
  { id: 90027, taacolorid: 90027, hex: "#e2ded2", rgb: "226,222,210", cmyk: "29,28,41,0", colorname: "Desert Sand" },
  { id: 90035, taacolorid: 90035, hex: "#f4efe5", rgb: "244,239,229", cmyk: "12,11,23,0", colorname: "Vintage White" },
  { id: 90024, taacolorid: 90024, hex: "#ffffff", rgb: "255,255,255", cmyk: "0,0,0,0", colorname: "White" },
]

const FONTS = [
  {
    label: 'Full Block',
    id: 1,
    file: 'full_block'
  },
  {
    label: 'Apache',
    id: 2,
    file: 'apache'
  },
  {
    label: 'Collegiate',
    id: 3,
    file: 'collegiate'
  },
  {
    label: 'Falcon',
    id: 4,
    file: 'falcon'
  },
  {
    label: 'Racer',
    id: 5,
    file: 'racer'
  },
  {
    label: 'Warrior 1',
    id: 6,
    file: 'warrior_1'
  },
  {
    label: 'University',
    id: 7,
    file: 'university'
  }
];

const DEFAULT = {
  FILL: "#FFFFFF",
  OUTLINE: "#111111",
  FONT: "full_block",
  FILL_ID: 90002,
  OUTLINE_ID: 90024,
  C1: 90019,
  C2: 90008,
  C3: 90024,
};

const DEFAULT_PRODUCT_DATA = {
  color_1: DEFAULT.C1,
  color_2: DEFAULT.C2,
  color_3: DEFAULT.C3,
  layout: {},
  design: {},
  product: {},
  front: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Team name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    logo: {
      url: '/images/graphic_placeholder.png',
      original_url: '',
    }
  },
  back: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Player name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
      size: 3,
    },
    logo: {
      url: '/images/graphic_placeholder.png',
      original_url: '',
    }
  },
  side: {
    artwork: 0,
    artwork_color_1: DEFAULT.C1,
    artwork_color_2: DEFAULT.C2,
    artwork_color_3: DEFAULT.C3,
    name: {
      value: 'Team name',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"Team name","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
    },
    number: {
      value: '00',
      fill: DEFAULT.FILL_ID,
      outline: DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://render-cdn.builder-api.foundersportgroup.com/api/builder/custom-text?json={"text":"00","size":800,"font":"full_block","strokes":[{"color":"rgb(17,17,17)"}],"color":"rgb(255,255,255)"}',
    },
    logo: {
      url: '',
      original_url: '',
    }
  },
};

export default {
  EMPTY_SVG_GROUPS,
  BACKUP_STOCK_COLOR_IDS,
  LAYER,
  DEFAULT,
  DEFAULT_PRODUCT_DATA,
  FONTS,
  COLORS,
}