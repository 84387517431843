<template>
  <div class="page closed-session-page">
    <div class="page-title">
      Data send back by Flash Builder
    </div>
    <div class="info-content">
      <div class="info-column">
        <div class="info-item">
          Old Save Code
        </div>
        <div class="info-item badge" v-show="data.OldSaveCode">
          {{ data.OldSaveCode }}
        </div>
      </div>
      <div class="info-column">
        <div class="info-item">
          New Save Code
        </div>
        <div class="info-item badge" v-show="data.SaveCode">
          {{ data.SaveCode }}
        </div>
      </div>
      <div class="info-column">
        <div class="info-item">
          Total Qty
        </div>
        <div class="info-item badge" v-show="data.TotalQty">
          {{ data.TotalQty }}
        </div>
      </div>
      <div class="info-column">
        <div class="info-item">
          Total Amount
        </div>
        <div class="info-item badge" v-show="data.TotalAmount">
          ${{ data.TotalAmount }}
        </div>
      </div>
    </div>
    <div class="info-table">
      <div class="table-header">
        <div class="table-item">
          Sku
        </div>
        <div class="table-item">
          Size
        </div>
        <div class="table-item">
          Unit Price
        </div>
        <div class="table-item">
          Quantity
        </div>
        <div class="table-item">
          Licensor
        </div>
      </div>
      <div class="table-body">
        <div class="table-row" v-for="(item, index) in data.items" :key="index">
          <div class="table-item">
            {{ item.Sku }}
          </div>
          <div class="table-item">
            {{ item.Size }}
          </div>
          <div class="table-item">
            {{ item.UnitPrice }}
          </div>
          <div class="table-item">
            {{ item.Quantity }}
          </div>
          <div class="table-item">
            {{ item.Licensor }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "TheFinalPage",
  methods: {
    returnURL() {
      setTimeout(() => {
        window.top.location.href = this.redirectURL
      }, 2000)
    }
  },
  computed: {
    ...mapState("customizations", ["finalInfo", "redirectURL"]),
    data() {
      return JSON.parse(this.finalInfo.data.d)
    }
  },
  mounted() {
    this.returnURL()
  }
}
</script>

<style lang="scss" scoped>
  #app {
    
    .badge {
      padding: .25em .4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: .25rem;
      color: #fff;
      background-color: #28a745;
    }
    .closed-session-page {
      * {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
        -webkit-touch-callout: text !important; /* iOS Safari */
        -webkit-user-select: text !important; /* Safari */
        -khtml-user-select: text !important; /* Konqueror HTML */
        -moz-user-select: text !important; /* Old versions of Firefox */
        -ms-user-select: text !important; /* Internet Explorer/Edge */
        user-select: text !important;
      }
      width: 100%;
      display: flex;
      flex-direction: column;
      .info-title {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .info-content {
        * {
          font-size: 1rem;
        }
        display: flex;
        .info-column {
          display: flex;
          flex-basis: 0;
          flex-grow: 1;
          .info-item {
            padding: 3px;
            line-height: 1rem;
          }
        }
      }
      .info-table {
        .table-header {
          display: flex;
          margin: 10px;
          .table-item {
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            font-weight: 700;
          }
        }
        .table-body {
          display: flex;
          flex-direction: column;
          margin: 10px;
          .table-row {
            display: flex;
            .table-item {
              display: flex;
              flex-basis: 0;
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
</style>