<template>
  <div id="app" @click="appClick">
    <Header />
    <div class="page-container">
      <div class="page-background page-left-bg"></div>
      <div class="page-background page-right-bg"></div>

      <router-view v-if="!loading && !error" />
      <div class="app-loader-container" v-else-if="loading && !error">
        <Loader />
      </div>
      <div v-else class="load-error">
        <strong>An error has ocurred:</strong>
        <span>{{ error.msg }}</span>
      </div>
    </div>
    <TheDebugBar v-if="debugEnabled"/>
  </div>
</template>

<script>
import Header from "@/components/Header";
//import Footer from "@/components/Footer";
import Loader from "@/components/Loader";
import TheDebugBar from "@/components/TheDebugBar";

import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions(["init"]),
    ...mapActions("menus", ["closeAll"]),
    appClick(e) {
      //Closes panels if clicking outside of them
      const validClickParents = ["color-list-wrapper", "color-picker-wrapper color-picker-large", "color-picker-list", "color-picker-wrapper", "customization-item-wrapper customization-item-large", "customization-item-wrapper customization-item-large error", "customization-item-wrapper", "customization-item-wrapper error", "customization-list"]
      const isColorSwatch = ((e.target.className != null) && (e.target.className.toString().indexOf('color-swatch') >= 0))
      if (!isColorSwatch && (!e.target.offsetParent || !e.target.offsetParent.className || validClickParents.indexOf(e.target.offsetParent.className) === -1)) {
        this.closeAll()
      } 
    }
  },
  computed: {
    ...mapState("customizations", ["session", "debugEnabled"]),
    ...mapState(["loading", "error"]),
    sessionError() {
      return this.session === "error";
    },
  },
  created() {
    this.init();
  },
  components: {
    Header,
    //  Footer,
    Loader,
    TheDebugBar
  },
};
</script>

<style lang="scss">
$font-dir: "/fonts/";
@font-face {
  font-family: "Forza";
  src: url("#{$font-dir}Forza-Medium.otf");
}
@font-face {
  font-family: "ForzaBold";
  src: url("#{$font-dir}Forza-Bold.otf");
}
@font-face {
  font-family: "SlateStd";
  src: url("#{$font-dir}SlateStd.otf");
}
@font-face {
  font-family: "SlateStd-Bold";
  src: url("#{$font-dir}SlateStd-Bold.otf");
}
body {
  margin: 0;
}
#app {
  * {
    box-sizing: border-box;
    font-family: "SlateStd";
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn {
    font-family: "ForzaBold";
  }

  .page-container {
    margin: 0 auto;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    // max-width: 1328px;
    .app-loader-container {
      min-height: 80vh;
      display: flex;
      align-items: center;
    }
    .page {
      display: flex;
      position: relative;
      width: 100%;
      max-width: 1328px;
      margin: 25px 0 100px;
      align-items: initial;
    }
    .page-background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .page-left-bg {
      background: url("/images/bg_left_tall.png");
      background-position: top left;
      background-repeat: repeat-y;
    }
    .page-right-bg {
      background: url("/images/bg_right_tall.png");
      background-repeat: repeat-y;
      background-position: top right;
    }
    .loader-container {
      display: flex;
      justify-content: center;
    }
    .load-error {
      margin: 50px;
      display: flex;
      justify-content: center;
      min-height: 250px;
      color: red;
      * {
        padding: 0 2px;
      }
    }
    .black-btn {
      display: flex;
      justify-content: center;
      padding: 5px;
      background: #000000 0% 0% no-repeat padding-box;
      border: 1px solid #000000;
      border-radius: 15px;
      cursor: pointer;
      span {
        color: #ffffff;
      }
    }
    .white-btn {
      display: flex;
      justify-content: center;
      cursor: pointer;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #000000;
      font-size: 16px;
      font-family: "SlateStd";
      &:hover {
        background-color: #000000;
        span {
          color: white;
        }
      }
      span {
        color: #000000;
      }
    }
  }
  .row {
    width: 100%;
    display: flex;
  }
  .fade {
    transition: opacity 0.15s linear 0s;
  }
  .hidden {
    display: none;
  }
}
</style>
