<template>
  <div class="page customize-page">
    <TheProductPreview />
    <Customizer />
  </div>
</template>

<script>
import TheProductPreview from "@/components/TheProductPreview";
import Customizer from "@/components/Product/Customizer";
import { mapActions } from "vuex";
export default {
  name: "TheCustomizePage",
  methods: {
    ...mapActions("navigation", ["setNavigationItems"]),
    ...mapActions(["setFrontSide", "setBackSide"]),
  },
  mounted() {
    this.setFrontSide();
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
  },
  data() {
    return {
      navigationItems: [
        "Gender",
        "Style",
        "Colors",
        "Design",
        "Layout",
        "Customize",
      ],
    };
  },
  components: {
    TheProductPreview,
    Customizer,
  },
};
</script>

<style lang="scss" scoped>
  #app {
    .customize-page {
      flex-wrap: wrap;
      display: flex;
      @media screen and (max-width: 780px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
</style>