<template>
  <div class="color-list-wrapper">
    <div class="color-picker-list">
      <h3 class="color-picker-list-title">
        Choose main color: {{ color.colorname }}
      </h3>
      <div class="color-lists-wrapper">
        <div class="color-list-container"  v-for="(list, listsIndex) in lists" :key="listsIndex">
          <ColorPickerSwatch v-for="(swatchColor, index) in colors.slice(itemsPerColumn * listsIndex, (itemsPerColumn * listsIndex) + itemsPerColumn)" :key="index" :color="swatchColor" :activeColor="color" v-on="{ selectColor }"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPickerSwatch from "@/components/Product/ColorPickerSwatch"
export default {
  name: "ColorPickerList",
  props: ["color", "colors"],
  computed: {
    lists() {
      return Math.ceil((this.colors.length / this.itemsPerColumn))
    }
  },
  components: {
    ColorPickerSwatch
  },
  methods: {
    selectColor(color) {
      this.$emit("selectColor", color);
    }
  },
  data() {
    return {
      itemsPerColumn: 11
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .color-list-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .color-picker-list {
        border: 1px solid #d6d6d6;
        background-color: #ffffff;
        padding: 10px;
        //width: 425px;
        max-width: 90%;
        width: 900px;
        .color-lists-wrapper {
          display: flex;
          .color-list-container {
            //width: 50%;
            margin-right: 10px;
          }
          @media screen and (max-width: 780px) {
            justify-content: space-around;
          }
        }
      }
      .color-picker-large {
        .color-picker-list {
          left: -25%;
        }
      }
    }
  }
</style>