<template>
  <div class="progress progress-striped active">
    <div role="progressbar progress-striped" :style="{ width }" class="progress-bar">
      <span>{{ label || 'Loading' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheProgressBar",
  props: ["progress", "label"],
  computed: {
    width() {
      return this.progress + "%"
    }
  },
}
</script>

<style lang="scss" scoped>
  $brand-default: #B0BEC5;
  $brand-primary: #2196F3;
  $brand-secondary: #323a45;
  $brand-success: #64DD17;
  $brand-warning: #FFD600;
  $brand-info:  #29B6F6;
  $brand-danger: #ef1c1c;
  $bg-light-gray: #f5f5f5;
  .progress {
    background-color: $bg-light-gray;
    border-radius: 3px;
    box-shadow:none;
    width: 100%;
    &.progress-xs {
      height: 5px;
      margin-top: 5px;
    }
    &.progress-sm {
      height: 10px;
      margin-top: 5px;
    }
    &.progress-lg {
      height: 25px;
    }
    &.vertical {
      position: relative;
      width: 20px;
      height: 200px;
      display: inline-block;
      margin-right: 10px;
      > .progress-bar {
        width: 100%!important;
        position: absolute;
        bottom: 0;
      }
      &.progress-xs {
        width: 5px;
        margin-top: 5px;
      }
      &.progress-sm {
        width: 10px;
        margin-top: 5px;
      }
      &.progress-lg {
        width: 30px;
      }
    }
  }

  .progress-bar {
    width: 100%;
    background-color: $brand-primary;
    box-shadow:none;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: white;
      padding: 2px;
    }
    &.text-left{
      text-align: left;
      span{
        margin-left: 10px;
      }
    }
    &.text-right{
      text-align: right;
      span{
        margin-right: 10px;
      }
    }
  }
  @mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
    background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
    background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  }

  @-webkit-keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }

  // Spec and IE10+
  @keyframes progress-bar-stripes {
    from  { background-position: 40px 0; }
    to    { background-position: 0 0; }
  }

  @mixin animation($animation) {
    -webkit-animation: $animation;
        -o-animation: $animation;
            animation: $animation;
  }
  .progress.active .progress-bar,
  .progress-bar.active {
    @include animation(progress-bar-stripes 2s linear infinite);
  }
  .progress-striped .progress-bar,
  .progress-bar-striped {
    @include gradient-striped;
    background-size: 40px 40px;
  }
  @mixin progress-bar-variant($color) {
    background-color: $color;
  }

  .progress-bar-secondary {
    @include progress-bar-variant($brand-secondary);
  }

  .progress-bar-default {
    @include progress-bar-variant($brand-default);
  }



  .progress-bar-success {
    @include progress-bar-variant($brand-success);
  }

  .progress-bar-info {
    @include progress-bar-variant($brand-info);
  }

  .progress-bar-warning {
    @include progress-bar-variant($brand-warning);
  }

  .progress-bar-danger {
    @include progress-bar-variant($brand-danger);
  }

</style>