<template>
  <div class="page roster-page">
    <TheProductPreview />
    <div class="roster-section">
      <div class="roster-section-body">
        <RosterRows
          v-if="hasRows"
          :rosterRows="rosterRows"
          :activeRowKey="activeRowKey"
          :showErrors="showErrors"
          v-on="{ updateRosterField, deleteRow, setActiveRow }"
        />
        <!-- hide if no roster rows and show warning -->
        <div v-else class="roster-information">
          Click on "Add new player" to start adding players
        </div>
      </div>
      <div class="roster-section-footer">
        <div class="customization-actions">
          <div class="btn white-btn customization-action" @click="addNewPlayer">
            <span>Add new player</span>
            <!-- add new row -->
          </div>
        </div>
        <div class="customization-actions-summary" v-if="hasRows">
          <div class="summary-totals">Product Total: ${{ productTotals }}</div>
          <div
            class="btn black-btn customization-action customization-action-add-to-cart"
            :class="{ disabled: !hasRows || hasIncompleteFields }"
            @click="addToCart"
          >
            <span :class="{ disabled: !hasRows || !hasIncompleteFields }">add to cart</span>
          </div>
          <p class="error" v-if="hasIncompleteFields && showErrors">
            Please enter sizes and quantities for all items before adding to cart.
          </p>
        </div>
        <TheRosterUploader />
      </div>
    </div>
  </div>
</template>

<script>
import TheProductPreview from "@/components/TheProductPreview"
import RosterRows from "@/components/Product/RosterRows"
import TheRosterUploader from "@/components/TheRosterUploader"

import { mapActions, mapState } from "vuex"
export default {
  name: "TheRosterPage",
  computed: {
    ...mapState(["roster", "selectedDesign", "selectedStyle", "activeGender", "teamName", "relatedPrices"]),
    ...mapState("layouts", ["activeFrontLayout", "activeBackLayout"]),
    
    graphicRequired() {
      return this.activeFrontLayout.code === "front-custom-graphic" || this.activeFrontLayout.code === "left-custom-graphic"
    },
    
    playerNameIsRequired() {
      return this.activeBackLayout.playerNameRequired
    },
    
    playerNumberIsRequired() {
      return this.activeBackLayout.playerNumberRequired
    },

    productTotals() {
      let total = 0;
      for (let index = 0; index < this.rosterRows.length; index++) {
        const rosterRow = this.rosterRows[index]
        const genderBase = rosterRow.gender.toLowerCase() === "men" || rosterRow.gender.toLowerCase() === "youth"
          ? "men" : "women"
        const genderMatchId = this.selectedStyle.gender_matches[genderBase]
        const rowPrice = this.relatedPrices[genderMatchId][rosterRow.size] 
          ? this.relatedPrices[genderMatchId][rosterRow.size]
          : this.relatedPrices[genderMatchId].base
        total += rosterRow.quantity * rowPrice;
      }
      return total.toFixed(2);
    },
    activeRow() {
      return this.rosterRows.find((row) => {
        return row.key === this.activeRowKey;
      });
    },
    hasRows() {
      return this.rosterRows.length;
    },
    hasIncompleteFields() {
      return this.rosterRows.find((row) => {
        return (row.quantity.length === 0 || row.quantity == 0 || row.size.length === 0)
      }) != null
    },
    genders() {
      return Object.keys(this.selectedStyle.gender_matches)
    },
    gender() {
      return this.genders[0].toLowerCase();
    },
    sizes() {
      return this.selectedStyle.sizes[this.gender];
    },
  },
  methods: {
    ...mapActions("navigation", ["setNavigationItems"]),
    ...mapActions(["setBackSide"]),
    ...mapActions(["requestRosterStamp", "setField", "switchGender"]),
    ...mapActions("customizations", ["applyRoster", "enableSentCustomizations"]),
    addToCart() {
      if (this.hasRows && !this.hasIncompleteFields) {
        
        this.showErrors = false
        this.applyRoster(this.rosterRows.map((row) => {
          //calc api gender name
          let GenderName = ""
          switch (row.gender.toLowerCase()) {
            case "men": {
              GenderName = "Male"; break;
            }
            case "youth": {
              GenderName = "Youth Male"; break;
            }
            case "women": {
              GenderName = "Female"; break;
            }
            case "girls": {
              GenderName = "Youth Female"; break;
            }
          }
          //calc price per unit
          const genderBase = row.gender.toLowerCase() === "men" || row.gender.toLowerCase() === "youth"
            ? "men" : "women"
          const genderMatchId = this.selectedStyle.gender_matches[genderBase]
          const rowPrice = this.relatedPrices[genderMatchId][row.size] 
            ? this.relatedPrices[genderMatchId][row.size]
            : this.relatedPrices[genderMatchId].base

          const PlayerName = this.playerNameIsRequired && row.name ? row.name.toUpperCase() : "", 
            PlayerNumber = this.playerNumberIsRequired ? row.number : ""
          //return info in format required by API  
          return { 
            TeamName: !this.graphicRequired ? this.teamName.value ? this.teamName.value.toUpperCase() : "" : "", 
            PlayerName: PlayerName,
            PlayerNumber,
            GenderName,
            Quantity: parseInt(row.quantity),
            Size: row.size, 
            UnitPrice: rowPrice,
            TaaskuId: this.selectedStyle.gender_matches[row.gender.toLowerCase()] + "-5B-" + row.size 
          };
        }))
      }
      else {
        this.showErrors = true
      }
    },
    addNewPlayer() {
      let newKey = this.rosterRows.length
        ? this.rosterRows[this.rosterRows.length - 1].key + 1
        : 0;
      this.rosterRows.push({
        key: newKey,
        name: "",
        number: "",
        //gender: this.genders[0],
        gender: this.selectedStyle.genders[0] ? this.selectedStyle.genders[0] : "Men",
        //size: this.sizes[0],
        size: "",
        quantity: "",
      });
      this.activeRowKey = newKey;
      this.requestTextStampForActiveRow();
      this.saveRosterData();
    },
    deleteRow(payload) {
      this.rosterRows = this.rosterRows.filter((row) => {
        return row.key !== payload.key;
      });
      if (payload.key === this.activeRowKey && this.rosterRows.length) {
        //deletes active row
        this.setActiveRow({
          key: this.rosterRows[0].key,
        });
      } else {
        if (!this.rosterRows.length) {
          this.setActiveRow({
            key: null,
          });
        }
      }
      this.saveRosterData();
    },
    updateRosterField(payload) {
      let itemIndex = this.rosterRows.findIndex((row) => {
        return row.key === payload.rowKey;
      });
      if (itemIndex != null) {
        if (payload.nameValue || payload.nameValue === "") this.rosterRows[itemIndex].name = payload.nameValue;
        if (payload.numberValue || payload.numberValue === "") this.rosterRows[itemIndex].number = payload.numberValue;
        if (payload.size) this.rosterRows[itemIndex].size = payload.size;
        if (payload.quantity)
          this.rosterRows[itemIndex].quantity = payload.quantity;
        if (payload.gender) {
          this.rosterRows[itemIndex].gender = payload.gender;
          this.switchGender({
            gender: payload.gender,
          }); //changes active gender and selected design
        }

        if (payload.requestTextStamp) {
          this.requestTextStampForActiveRow();
        }
        this.saveRosterData();
      }
    },
    setActiveRow(payload) {
      if (payload.key == null) return false;
      if (this.activeRowKey !== payload.key) {
        this.activeRowKey = payload.key;
        this.requestTextStampForActiveRow();
        if (
          this.activeGender.toLowerCase() !==
          this.activeRow.gender.toLowerCase()
        ) {
          this.switchGender({
            gender: this.activeRow.gender.toLowerCase(),
          }); //changes active gender and selected design
        }
      }
    },
    requestTextStampForActiveRow() {
      if (this.activeRow != null)
        this.requestRosterStamp({
          playerName: this.activeRow.name,
          number: this.activeRow.number,
        });
    },
    saveRosterData() {
      this.setField({
        field: "roster",
        value: this.rosterRows,
      });
    },
  },
  mounted() {
    this.setBackSide();
    this.enableSentCustomizations()
    this.setNavigationItems({
      navigationItems: this.navigationItems,
    });
    //init roster in case something went wrong somehow
    this.rosterRows = this.roster;
    if (!this.hasRows) {
      this.addNewPlayer();
    } else {
      this.setActiveRow({
        key: this.rosterRows[0].key,
      });
      this.requestTextStampForActiveRow();
    }
  },
  data() {
    return {
      navigationItems: [
        "Gender",
        "Style",
        "Colors",
        "Design",
        "Layout",
        "Customize",
        "Roster",
      ],
      rosterRows: [],
      activeRowKey: 0,
      showErrors: false,
    };
  },
  components: {
    TheProductPreview,
    RosterRows,
    TheRosterUploader
  },
};
</script>

<style lang="scss" scoped>
#app {
  .roster-page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .roster-section {
      width: 60%;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000040;
      padding: 50px 25px;
      overflow-x: scroll;
      .roster-section-footer {
        margin-top: 20px;
        .customization-actions-summary {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          .summary-totals {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: #2e2e2e;
            font-size: 24px;
          }
          .customization-action-add-to-cart {
            padding: 10px 30px;
            text-transform: uppercase;
            margin: 15px 10px;
            text-transform: uppercase;
            font-size: 24px;
            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
          .error {
            color: red;
          }
        }
        .customization-actions {
          display: flex;
          padding: 7px;
          .customization-action {
            display: flex;
            align-items: center;
            margin-right: 10px;
            text-transform: uppercase;
            width: 210px;
            height: 40px;
            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }
    @media screen and (max-width: 780px) {
      flex-direction: column;
      align-items: center;
      .roster-section {
        max-width: 515px;
        width: 90%;
        .customization-actions-summary {
          align-items: center !important;
          margin-top: 25px;
        }
      }
    }
  }
}
</style>