import { render, staticRenderFns } from "./TheColorsPage.vue?vue&type=template&id=fb2d912e&scoped=true&"
import script from "./TheColorsPage.vue?vue&type=script&lang=js&"
export * from "./TheColorsPage.vue?vue&type=script&lang=js&"
import style0 from "./TheColorsPage.vue?vue&type=style&index=0&id=fb2d912e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb2d912e",
  null
  
)

export default component.exports