<template>
  <div class="style-item" :title="productTitle" @click="select" :class="{ active: isActive }">
    <div class="style-item-label">
      {{ item.style_id }}
    </div>
    <div class="style-item-body">
      <img class="style-item-image" :src="thumbnail" :alt="item.name"/>
    </div>
    <div class="style-item-title">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: "TheStyleItem",
  props: ["item"],
  computed: {
    ...mapState(["selectedStyle"]),
    thumbnail() {
      return '/images/styles/' + this.item.style_id + '.png'
    },
    productTitle() {
      return this.item.style_id + ' - ' + this.item.name
    },
    isActive() {
      return this.selectedStyle?.style_id === this.item.style_id
    }
  },
  methods: {
    ...mapActions(["setField", "resetDesign"]),
    ...mapActions("navigation", ["navigate"]),
    ...mapActions("customizations", ["selectStyle"]),
    select() {
      //Set active product and move to the next step
      if (!this.isActive) {
        this.resetDesign()
        
        this.selectStyle({
          styleId: this.item.style_id
        })
        this.setField({
          field: "selectedStyle",
          value: this.item
        })
        this.navigate({
          destination: "TheColorsPage"
        })
      }
      else {
        this.navigate({
          destination: "TheColorsPage"
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .style-item {
      position: relative;
      max-width: 285px;
      width: 20%;
      height: 365px;
      background-color: white;
      padding: 10px;
      margin: 5px 25px;
      -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      overflow: hidden;
      &.active {
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
      }
      .style-item-label {
        font-family: "Forza";
        font-size: 16px;
        background-color: #F9F9F9;
        border-radius: 5px;
        position: absolute;
        left: 10px;
        top: 10px;
        padding: 2px 10px;
      }
      .style-item-body {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        left: 0;
        bottom: 41px;
      }
      .style-item-title {
        font-family: "Forza";
        font-size: 18px;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 10px;
        width: 100%;
      }
      &:hover {
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 1);
      }
      @media screen and (max-width: 1078px) {
        width: 40%;
        max-width: 40%;
      }
      @media screen and (max-width: 780px) {
        width: 90%;
        max-width: 90%;
      }
    }
  }
</style>