const layouts = {
  namespaced: true,
  state: {
    activeFrontLayout: null,
    activeBackLayout: null,
    layouts: {
      front: [
        {
          label: "Team Name + Center Number",
          name: {
            width: "45%", x: "28.5%", y: "20%", height: "9%"
          },
          number: {
            width: "15%", x: "44%", y: "30%", height: "15%"
          },
          code: "team-name-center-number",
          graphicRequired: false
        },
        {
          label: "Team Name + Left Number",
          name: {
            width: "45%", x: "28.5%", y: "20%", height: "9%"
          },
          number: {
            width: "15%", x: "55%", y: "30%", height: "15%"
          },
          code: "team-name-left-number",
          graphicRequired: false
        },
        {
          label: "Front Custom Graphic",
          graphic: {
            width: "35%", x: "32.5%", y: "20%", height: "28%"
          },
          code: "front-custom-graphic",
          graphicRequired: true
        },
        {
          label: "Left Chest Custom Graphic",
          graphic: {
            width: "100", x: "465", y: "200", height: "100"
          },
          code: "left-custom-graphic",
          graphicRequired: true
        },
      ],
      back: [
        {
          label: "Player Name & Number",
          name: {
            width: "45%", x: "28.5%", y: "15%", height: "9%"
          },
          number: {
            width: "35%", x: "34%", y: "23%", height: "30%"
          },
          playerNameRequired: true,
          playerNumberRequired: true,
          code: "name-and-number"
        },
        {
          label: "Number Only",
          number: {
            width: "35%", x: "34%", y: "23%", height: "30%"
          },
          playerNameRequired: false,
          playerNumberRequired: true,
          code: "number"
        },
        {
          label: "Player Name Only",
          name: {
            width: "45%", x: "28.5%", y: "15%", height: "9%"
          },
          playerNameRequired: true,
          playerNumberRequired: false,
          code: "name"
        },
        {
          label: "Blank",
          playerNameRequired: false,
          playerNumberRequired: false,
          code: "blank"
        },
      ]
    },
    layoutsProperties: [{ 
        styleId: 8826, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "29%",
                  y: "21%",
                  width: "43%",
                  height: "9%"
                },
                number: {
                  x: "40.5%",
                  y: "32%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "29%",
                  y: "21%",
                  width: "43%",
                  height: "9%"
                },
                number: {
                  x: "50%",
                  y: "32%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "32.5%",
                  y: "23%",
                  width: "35%",
                  height: "28%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "455px",
                  y: "230px",
                  width: "100px",
                  height: "100px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "29%",
                  y: "13%",
                  width: "43%",
                  height: "9%"
                },
                number: {
                  x: "33%",
                  y: "23%",
                  width: "35%",
                  height: "28%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8820, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "29%",
                  y: "21%",
                  width: "43%",
                  height: "9%"
                },
                number: {
                  x: "40.5%",
                  y: "32%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "29%",
                  y: "21%",
                  width: "43%",
                  height: "9%"
                },
                number: {
                  x: "50%",
                  y: "32%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "32.5%",
                  y: "23%",
                  width: "35%",
                  height: "28%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "455px",
                  y: "230px",
                  width: "100px",
                  height: "100px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "29%",
                  y: "13%",
                  width: "43%",
                  height: "9%"
                },
                number: {
                  x: "33%",
                  y: "23%",
                  width: "35%",
                  height: "28%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8822, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "17%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "41%",
                  y: "28%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "17%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "51%",
                  y: "28%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "28%",
                  y: "15%",
                  width: "45%",
                  height: "35%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "460px",
                  y: "160px",
                  width: "130px",
                  height: "130px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "28.5%",
                  y: "8%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "33%",
                  y: "18%",
                  width: "35%",
                  height: "28%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8823, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "25%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "41%",
                  y: "35%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "25%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "51%",
                  y: "35%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "28%",
                  y: "26%",
                  width: "45%",
                  height: "35%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "455px",
                  y: "167px",
                  width: "130px",
                  height: "130px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "28.5%",
                  y: "17%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "33%",
                  y: "26%",
                  width: "35%",
                  height: "25%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8846, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "25%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "38%",
                  y: "37%",
                  width: "25%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "25%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "47%",
                  y: "37%",
                  width: "25%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "30%",
                  y: "22%",
                  width: "40%",
                  height: "40%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "460px",
                  y: "200px",
                  width: "130px",
                  height: "130px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "28.5%",
                  y: "15%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "30%",
                  y: "26%",
                  width: "40%",
                  height: "30%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8840, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "21%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "38%",
                  y: "33%",
                  width: "25%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "21%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "47%",
                  y: "33%",
                  width: "25%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "30%",
                  y: "22%",
                  width: "40%",
                  height: "40%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "460px",
                  y: "220px",
                  width: "130px",
                  height: "130px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "28.5%",
                  y: "15%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "30%",
                  y: "26%",
                  width: "40%",
                  height: "30%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8848, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "27.5%",
                  y: "23%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "38%",
                  y: "33%",
                  width: "25%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "27.5%",
                  y: "23%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "55%",
                  y: "33%",
                  width: "15%",
                  height: "13%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "30%",
                  y: "23%",
                  width: "40%",
                  height: "40%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "460px",
                  y: "220px",
                  width: "130px",
                  height: "130px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "28.5%",
                  y: "8%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "30%",
                  y: "18%",
                  width: "40%",
                  height: "28%"
                }
              }
            },
          ]
        },
      },
      { 
        styleId: 8843, 
        layouts: {
          front: [
            {
              code: 'team-name-center-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "20%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "38%",
                  y: "33%",
                  width: "25%",
                  height: "15%"
                }
              }
            },
            {
              code: 'team-name-left-number',
              properties: {
                name: {
                  x: "28.5%",
                  y: "20%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "51%",
                  y: "31%",
                  width: "20%",
                  height: "15%"
                }
              }
            },
            {
              code: 'front-custom-graphic',
              properties: {
                graphic: {
                  x: "30%",
                  y: "20%",
                  width: "40%",
                  height: "40%"
                }
              }
            },
            {
              code: 'left-custom-graphic',
              properties: {
                graphic: {
                  x: "455px",
                  y: "200px",
                  width: "130px",
                  height: "130px"
              }
            }
          }],
          back: [
            {
              properties: {
                name: {
                  x: "28.5%",
                  y: "12%",
                  width: "45%",
                  height: "9%"
                },
                number: {
                  x: "30%",
                  y: "22%",
                  width: "40%",
                  height: "28%"
                }
              }
            },
          ]
        },
      },
    ],
  },
  actions: {
    resetActiveLayouts({ commit }) {
      commit("resetActiveLayouts")
    },
    setActiveFrontLayout({ commit }, payload) {
      commit("setActiveFrontLayout", payload)
    },
    setActiveBackLayout({ commit }, payload) {
      commit("setActiveBackLayout", payload)
    }
  },
  mutations: {
    setActiveFrontLayout: (state, payload) => {
      state.activeFrontLayout = payload
    },
    setActiveBackLayout: (state, payload) => {
      state.activeBackLayout = payload
    },
    resetActiveLayouts: (state) => {
      state.activeFrontLayout = null
      state.activeBackLayout = null
    }
  },
}

export default layouts