import Vue from "vue";
import VueRouter from "vue-router";

/*
// Views
*/
import TheGenderPage from "../views/TheGenderPage.vue";
import TheStylePage from "../views/TheStylePage.vue";
import TheColorsPage from "../views/TheColorsPage.vue";
import TheDesignPage from "../views/TheDesignPage.vue";
import TheLayoutPage from "../views/TheLayoutPage.vue";
import TheCustomizePage from "../views/TheCustomizePage.vue";
import TheRosterPage from "../views/TheRosterPage.vue";
import TheFinalPage from "../views/TheFinalPage.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "TheGenderPage",
    component: TheGenderPage
  },
  {
    path: "/",
    name: "TheStylePage",
    component: TheStylePage
  },
  {
    path: "/",
    name: "TheColorsPage",
    component: TheColorsPage
  },
  {
    path: "/",
    name: "TheDesignPage",
    component: TheDesignPage
  },
  {
    path: "/",
    name: "TheLayoutPage",
    component: TheLayoutPage
  },
  {
    path: "/",
    name: "TheCustomizePage",
    component: TheCustomizePage
  },
  {
    path: "/",
    name: "TheRosterPage",
    component: TheRosterPage
  },
  {
    path: "/",
    name: "TheFinalPage",
    component: TheFinalPage
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
