<template>
  <div class="header">
    <div class="header-logo">
      <img class="header-logo-img" src="/images/flash_logo.png" alt="Flash Logo" />
      <img class="header-logo-img" src="/images/flash_bar.png" alt="Flash Bar" />
    </div>
    <div class="header-navigation">
      <div class="navigation-items-container" v-if="hasNavigationItems">
        <TheNavigationItem v-for="(item, index) in navigationItems" :key="index" :item="item"/>
      </div>  
      <div class="navigation-info" v-else>
        NO MINIMUMS. NO HASSLE.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import TheNavigationItem from "@/components/Header/TheNavigationItem"

export default {
  name: "Header",
  computed: {
    ...mapState(["selectedStyle", "selectedDesign"]),
    ...mapState("navigation", ["navigationItems", "enabledNavigationItems"]),
    hasNavigationItems() {
      return this.enabledNavigationItems && this.enabledNavigationItems.length
    },
    routeName() {
      return this.$route.name;
    },
    /*styleActive() {
      return this.routeName === "Style";
    },
    designActive() {
      return this.routeName === "Design";
    },
    customizeActive() {
      return this.routeName === "Customize";
    },
    sizesActive() {
      return this.routeName === "Roster";
    },
    hasSelectedStyle() {
      return this.selectedStyle != null;
    },
    hasSelectedDesign() {
      return this.selectedDesign != null;
    },
    designEnabled() {
      return this.hasSelectedStyle;
    },
    customizeEnabled() {
      return this.selectedDesign != null;
    },
    rosterEnabled() {
      return this.selectedDesign && this.routeName === "Customize"; //allowed without changing customizations
    },
    */
  },
  methods: {
    ...mapActions(["goToStep"])
  },
  components: {
    TheNavigationItem
  }
};
</script>

<style lang="scss" scoped>
#app {
  .header {
    //max-width: 1328px;
    margin: 0 auto;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    padding-bottom: 9px;
    .header-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      .header-logo-img {
        max-height: 100%;
        max-width: 50%;
        padding: 5px;
      }
    }
    .navigation-info {
      flex: 1;
      text-align: center;
      padding: 5px 0;
    }
    .header-navigation {
      display: flex;
      align-items: center;
      height: fit-content;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      font-family: "SlateStd";
      font-size: 18px;
      font-weight: 600;
      .navigation-items-container {
        display: flex;
        flex-wrap: nowrap;
        margin: 0 auto;
        height: 100%;
        width: 1328px;
        padding: 0 5%;
        justify-content: center;
      }
    }
  }
}
</style>