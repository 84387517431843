import Vue from "vue"
import Vuex from "vuex"
import router from '../router'
import axios from 'axios'
import CONSTANTS from '@/constants.js'

//Modules
import graphics from "./modules/graphics"
import menus from "./modules/menus"
import navigation from "./modules/navigation"
import layouts from "./modules/layouts"
import customizations from "./modules/customizations"

Vue.use(Vuex);

const //endPointURL = "https://qa-ss.teamworkathletic.com/",
  apiUrl = process.env.VUE_APP_ENDPOINT //todo env

export default new Vuex.Store({
  state: {
    loading: true,
    error: false,
    selectedStyle: null,
    selectedDesign: null,
    activeSide: "front",
    activeGender: null,
    frontMode: "text",
    availableDesigns: null,
    relatedDesigns: null,
    relatedPrices: null,
    colors: CONSTANTS.COLORS,
    styles: [
    ],
    color_1: CONSTANTS.DEFAULT.C1,
    color_2: CONSTANTS.DEFAULT.C2,
    color_3: CONSTANTS.DEFAULT.C3,
    fonts: CONSTANTS.FONTS,
    roster: [],
    teamName: {
      value: 'Team name',
      fill: CONSTANTS.DEFAULT.FILL_ID,
      outline: CONSTANTS.DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Team Name ","size":800,"font":"full_block","strokes":[{"color":"rgb(255,255,255)"}],"color":"rgb(17,17,17)"}',
    },
    number: {
      value: '00',
      fill: CONSTANTS.DEFAULT.FILL_ID,
      outline: CONSTANTS.DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"00 ","size":800,"font":"full_block","strokes":[{"color":"rgb(255,255,255)"}],"color":"rgb(17,17,17)"}',
    },
    playerName: {
      value: 'Player name',
      fill: CONSTANTS.DEFAULT.FILL_ID,
      outline: CONSTANTS.DEFAULT.OUTLINE_ID,
      font: 1,
      visible: true,
      stamp_url: 'https://rendercdn.builder.foundersport.com/api/builder/custom-text?json={"text":"Player Name","size":800,"font":"full_block","strokes":[{"color":"rgb(255,255,255)"}],"color":"rgb(17,17,17)"}',
    },
    logo: {
      url: '/images/graphic_placeholder.png',
      original_url: '',
    },
  },

  getters: {
    getStateByName: (state) => (data) => {
      return state[data.name];
    },

    getColorHexByField: (state) => (data) => {
      let color_id = state[data.field]
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === color_id) {
          return element.hex;
        }
      }
      return '#FFFFFF'; //White placeholder when data still didn't come from the API
    },

    getColorNameByField: (state) => (data) => {
      let side = data.side;
      let color_id = data.isArtwork
        ? state[side][data.field]
        : !data.side || !data.subfield
          ? state[data.field]
          : state[side][data.field][data.subfield];
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === color_id) {
          return element.name;
        }
      }
      return false;
    },

    getColorByField: (state) => (data) => {
      let color_id = data.parent ? state[data.parent][data.field] : state[data.field];
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === color_id) {
          return element;
        }
      }
      return null;
    },

    getColorById: (state) => (id) => {
      for (let index = 0; index < state.colors.length; index++) {
        const element = state.colors[index];
        if (element.id === id) {
          return element;
        }
      }
      return false;
    },

    getAllColors: (state) => {
      return state.colors;
    },
  },

  mutations: {
    setInit: (state, payload) => {
      state.styles = payload.products;
      //state.colors = payload.colors;
    },
    setError: (state, payload) => {
      state.error = payload
    },
    resetField: (state, field) => {
      state[field] = null;
    },
    setField: (state, payload) => {
      payload.parent
        ? state[payload.parent][payload.field] = payload.value
        : state[payload.field] = payload.value
    },
    setSubField: (state, payload) => {
      state[payload.field][payload.subfield] = payload.value
    },
    setStyle: (state, payload) => {
      state.selectedStyle = payload;
    },
    setAvailableDesigns: (state, payload) => {
      state.availableDesigns = payload.data;
    },
    setDesign: (state, payload) => {
      state.selectedDesign = payload;
    },
    updateGenderDesign: (state, payload) => {
      state.selectedStyle.backgrounds = payload.backgrounds
      state.relatedDesigns = payload.related_designs

      if (payload.sizes) {
        state.selectedStyle.sizes = payload.sizes
      }

      if (payload.prices) {
        state.relatedPrices = payload.prices
      }
    },
    resetGenderFromSelectedStyle: (state) => {
      if (state.selectedStyle && state.selectedStyle.genders)
        state.activeGender = state.selectedStyle.genders[0].toLowerCase()
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    updateTextStamp: (state, payload) => {
      state[payload.mainField].stamp_url = payload.stamp_url
    },

    updateFirstRosterRow: (state, payload) => {
      state.roster[0][payload.field] = payload.value
    },

    switchGender: (state, payload) => {
      state.activeGender = payload.gender
    },

    setProductOrderColors: (state, payload) => {
      state.color_1 = payload.Decoration.PrimaryColorId
      state.color_2 = payload.Decoration.SecondaryColorId
      state.color_3 = payload.Decoration.TertiaryColorId
      state.teamName.fill = payload.Decoration.TeamNameColorId
      state.teamName.outline = payload.Decoration.TeamNameOutlineColorId
      state.playerName.fill = payload.Decoration.PlayerNameColorId
      state.playerName.outline = payload.Decoration.PlayerNameOutlineColorId
      state.number.fill = payload.Decoration.PlayerNoColorId
      state.number.outline = payload.Decoration.PlayerNoOutlineColorId
    }

  },
  actions: {
    //general
    init({ commit, dispatch }) {
      dispatch("startLoading");
      axios.get(
        apiUrl + "api/sns/init"
      )
        .then((response) => {
          commit("setInit", response.data);
          dispatch("customizations/createFlashBuilderSession", null, { root: true })
          dispatch("stopLoading");
        })
    },
    startLoading({ commit }) {
      commit("startLoading");
    },
    stopLoading({ commit }) {
      commit("stopLoading");
    },
    setError({ commit }, payload) {
      commit("setError", payload)
    },
    goToStep({ state, commit }, step) {
      let stepsRouteNames = ["Style", "Design", "Customize", "Roster"]
      let destination = stepsRouteNames[step - 1]
      if (destination !== router.currentRoute.name) {
        if (step < 3 && state.roster.length > 1) {
          commit("setField", {
            field: "roster",
            value: []
          })
          router.push({ name: destination });
        }
        else {
          router.push({ name: destination });
        }
      }
    },

    resetField({ commit }, field) {
      commit("resetField", field);
    },
    setField({ commit }, payload) {
      commit("setField", payload);
    },
    setSubField({ commit }, payload) {
      commit("setSubField", payload);
    },

    //actions during navigation
    resetStyle({ dispatch }) { //when changing gender, resets style -> design
      dispatch("resetField", "selectedStyle")
      dispatch("resetDesign")
    },

    resetDesign({ dispatch }) { //when changing style
      dispatch("resetField", "selectedDesign")
    },

    //designs
    setAvailableDesigns({ commit }, payload) {
      commit("setAvailableDesigns", payload);
    },
    setDesign({ commit }, payload) {
      commit("setDesign", payload);
    },
    updateGenderDesign({ commit }, payload) {
      commit("updateGenderDesign", payload)
    },
    resetGenderFromSelectedStyle({ commit }) {
      commit("resetGenderFromSelectedStyle")
    },
    restoreSavedDesign({ state, dispatch, commit }, payload) {
      dispatch("startLoading")

      //select gender
      const genderName = payload.Product.SelectedGenderName.replace("s", "").toLowerCase()
      commit("switchGender", { gender: genderName })

      const styleId = payload.Product.SkuStyleNumber,
        ProductRetailId = payload.Product.ProductRetailId

      let UrlDesignName = payload.Product.UrlDesignName

      //cure design name for restore session, because it comes with different names just because of yes...
      if (UrlDesignName) {
        UrlDesignName = UrlDesignName.toLowerCase()
          .replace("_v1", "")
          .replace("_v2", "")
          .replace("_v3", "")
          .replace("_v4", "")
          .replace("_v5", "")

          .replace("v2", "")
          .replace("v3", "")
          .replace("v4", "")
          .replace("v5", "")

          .trim()
      }

      //select style
      //dispatch("customizations/selectStyle", { id: styleId, ignoreLoading: true })
      const style = state.styles[genderName].find((item) => {
        return item.style_id === styleId
      })
      commit("setField", {
        field: "selectedStyle",
        value: style
      })

      //set active layout
      const hasLogo = payload.Decoration?.UploadedGraphicPreviewUrl,
        teamname = payload.Decoration?.TeamName,
        hasPlayerName = payload.Decoration?.PlayerName.length > 0,
        hasPlayerNumber = payload.Decoration?.PlayerNumber.length > 0

      //set team name
      commit("setSubField", {
        field: "teamName",
        subfield: "value",
        value: teamname
      })

      //select colors
      if (payload.Decoration?.PrimaryColorId) {
        commit("setProductOrderColors", payload)

        dispatch("requestTextStamp", {
          mainField: "teamName",
          value: teamname
        })

        dispatch("requestTextStamp", {
          mainField: "number",
          value: "00"
        })

        dispatch("requestTextStamp", {
          mainField: "playerName",
          value: "Player Name"
        })
      }

      //set logo
      if (hasLogo) {
        commit("graphics/setApiLogo", {
          PreviewImageUrl: payload.Decoration.UploadedGraphicPreviewUrl,
          FullSizeImageUrl: payload.Decoration.UploadedGraphicFullSizeUrl
        })
      }

      //send style and design info back to api to initialize Product item on their end
      dispatch("customizations/selectStyle", {
        SaveCode: state.customizations.saveCode,
        DesignNumber: ProductRetailId,
        styleId,
        ignoreLoading: true
      })

      if (UrlDesignName) {
        //select design and layout
        const design = style.design?.find((item) => {
          return item.name.toLowerCase().replaceAll(" ", "_") === UrlDesignName
        })

        if (design) {

          commit("setField", {
            field: "selectedDesign",
            value: design
          })

          const requestObject = {
            flash_id: ProductRetailId
          }
          axios.get(
            apiUrl + "api/sns/flash-id",
            {
              params: requestObject
            }
          )
            .then((res) => {
              //update available design for all genders
              const relatedBackgrounds = []
              const relatedSizes = []
              const response = res.data
              const backgrounds = response.related.backgrounds
              const sizes = response.related.sizes
              const keys = Object.keys(backgrounds)

              for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                const shadows = backgrounds[key]
                const genderSizes = sizes[key]

                for (let indexy = 0; indexy < Object.keys(shadows).length; indexy++) {
                  const shadowKey = Object.keys(shadows)[indexy]
                  const shadow = shadows[shadowKey]
                  if (shadow != null) {
                    relatedBackgrounds[shadowKey] = shadow
                  }
                }

                for (let indexy = 0; indexy < Object.keys(genderSizes).length; indexy++) {
                  const sizeKey = Object.keys(genderSizes)[indexy]
                  const size = genderSizes[sizeKey]
                  if (size != null) {
                    relatedSizes[sizeKey] = size
                  }
                }

              }
              dispatch("updateGenderDesign", {
                backgrounds: relatedBackgrounds,
                related_designs: response.related.designs,
                sizes: relatedSizes,
                prices: response.related.prices
              }, { root: true })
              //set layout and move
              //set active front
              const layouts = state.layouts.layouts
              const frontLayout = layouts.front.find((item) => {
                return item.code === response.layout
              })
              dispatch("layouts/setActiveFrontLayout", frontLayout)
              //set active back
              const backLayoutCode = 
                hasPlayerName 
                  ? hasPlayerNumber 
                    ? 'name-and-number'
                    : 'name'
                  : hasPlayerNumber
                    ? 'number'
                    : 'blank'
              const backLayout = layouts.back.find((item) => {
                return item.code === backLayoutCode
              })
              dispatch("layouts/setActiveBackLayout", backLayout)

              //set roster
              const rosterItems = payload.Roster.Items
              if (rosterItems && rosterItems.length) {
                let roster = []
                for (let index = 0; index < rosterItems.length; index++) {
                  const rosterItem = rosterItems[index]
                  const rosterItemGender = rosterItem.GenderName === "Male"
                    ? "men"
                    : rosterItem.GenderName === "Youth Male"
                      ? "youth"
                      : rosterItem.GenderName === "Female"
                        ? "women" : "girls"
                  roster.push({
                    key: index,
                    name: rosterItem.PlayerName.toUpperCase(),
                    number: rosterItem.PlayerNumber,
                    gender: rosterItemGender,
                    size: rosterItem.Size,
                    quantity: rosterItem.Quantity,
                  })
                }

                commit("setField", {
                  field: "roster",
                  value: roster
                })

                dispatch("requestRosterStamp", {
                  playerName: roster[0]?.name.toUpperCase(),
                  number: roster[0]?.number,
                })

                dispatch("stopLoading")

                //move
                dispatch("navigation/navigate", {
                  destination: "TheRosterPage",
                });

              }
              else {
                dispatch("stopLoading")

                dispatch("navigation/navigate", {
                  destination: "TheRosterPage",
                });
              }


            })

        }
        else {
          dispatch("stopLoading")
        }

      }
      else {
        dispatch("navigation/navigate", {
          destination: "TheDesignPage",
        });

        dispatch("stopLoading")

      }

    },

    //product
    setStyle({ commit, dispatch }, payload) {
      dispatch("startLoading")
      axios.get(
        apiUrl + "api/sns/designs/" + parseInt(payload.id),
      )
        .then((response) => {
          commit("setStyle", payload);
          dispatch("setAvailableDesigns", response);
          dispatch("stopLoading")
        })
    },

    switchGender({ commit }, payload) {
      commit("switchGender", payload)
    },

    setFrontSide({ commit }) {
      commit("setField", {
        field: "activeSide",
        value: "front"
      })
    },

    setBackSide({ commit }) {
      commit("setField", {
        field: "activeSide",
        value: "back"
      })
    },

    requestTextStamp({ getters, state, commit }, payload) {
      let color = {
        fill: getters.getColorById(state[payload.mainField].fill).hex.replace("#", "%23"),
        outline: getters.getColorById(state[payload.mainField].outline).hex.replace("#", "%23")
      }
      const value = payload.value != null ? payload.value : state[payload.mainField].value
      //const value = payload.value != null ? payload.value : ""
      axios.get(
        apiUrl + "api/v2/text-stamp?fill=" + color.fill + "&outline=" + color.outline + "&value=" + value + "&font=full_block"
      ).
        then((response) => {
          commit("updateTextStamp", {
            mainField: payload.mainField,
            stamp_url: response.data
          })
        })
    },

    updateFirstRosterRow({ commit }, payload) {
      commit("updateFirstRosterRow", payload)
    },

    requestRosterStamp({ dispatch }, payload) {
      const fields = ["playerName", "number"]
      for (let index = 0; index < fields.length; index++) {
        const field = fields[index];
        dispatch("requestTextStamp", {
          mainField: field,
          value: payload[field]
        })
      }
    },

    setProductOrder({ commit, dispatch }, payload) {
      commit("setProductOrderColors", payload)
      dispatch("navigation/navigate", {
        destination: "TheColorsPage",
      });
    },

    uploadRoster({ state, commit, dispatch }, payload) {
      dispatch("startLoading");
      let formData = new FormData
      formData.append("file", payload.file)
      axios.post(
        apiUrl + "api/sns/roster",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )
        .then((response) => {
          const genders = Object.keys(state.selectedStyle.gender_matches).filter((match) => {
            return state.selectedStyle.gender_matches[match] != null
          })
          const parsed = response.data.map((item, index) => {
            const row = item[0]
            const genderName = row[2].replace('Boys', 'Youth')
            const validGender = genders.indexOf(genderName.toLowerCase()) > -1
            return {
              key: index,
              name: row[0] != null ? row[0] : "",
              number: row[1] != null ? row[1] : "",
              //gender: this.genders[0],
              gender: validGender ? genderName : '',
              //size: this.sizes[0],
              size: validGender ? row[3].replace('[ Men ]', "").replace('[ Women ]', "").replace('[ Girls ]', "").replace('[ Boys ]', "").trim() : '',
              quantity: row[4],
            }
          })
          commit("setField", {
            field: "roster",
            value: parsed
          })
        })
        .finally(() => {
          dispatch("stopLoading");
        })
    }

  },
  modules: {
    graphics,
    menus,
    navigation,
    layouts,
    customizations
  }
});
