<template>
  <div class="product-preview">
    <div class="product-preview-vector-container">
      <Vector :svgs="svgs" :backgrounds="backgrounds" :layout="layout" :relatedGenderMatchId="relatedGenderMatchId"/>
    </div>
    <div class="product-preview-sides">
      <div
        class="product-preview-side-wrapper"
        v-for="sideName in sides"
        :key="sideName"
      >
        <span
          class="product-preview-side"
          :class="{ active: sideName === activeSide }"
          v-if="hasSideName(sideName)"
          @click="setActiveSide(sideName)"
        >
          {{ sideName }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vector from "@/components/Vector";
import { mapActions, mapState } from "vuex";
export default {
  name: "TheProductPreview",
  computed: {
    ...mapState(["selectedDesign", "selectedStyle", "activeSide", "activeGender", "relatedDesigns"]),
    ...mapState("layouts", ["activeFrontLayout", "activeBackLayout"]),

    relatedGenderMatchId() {
      const baseGender = 
        this.activeGender.toLowerCase() === "men" || this.activeGender.toLowerCase() === "youth"
         ? "men"
         : "women"
      return this.selectedStyle.gender_matches[baseGender]
    },
    svgs() {
     // return this.selectedDesign.svg_data[this.activeSide];
     return this.relatedDesigns[this.relatedGenderMatchId] 
      ? this.relatedDesigns[this.relatedGenderMatchId]?.svg_data ? this.relatedDesigns[this.relatedGenderMatchId]?.svg_data[this.activeSide] : null
      : null
    },
    backgrounds() {
      return this.selectedStyle.backgrounds;
    },
    layouts() {
      return this.selectedStyle.layouts;
    },
    layout() {
      return this.activeSide === "front"
        ? this.activeFrontLayout
        : this.activeBackLayout;
    },
  },
  methods: {
    ...mapActions(["setField"]),
    hasSideName(sideName) {
      return this.selectedDesign.color === "precolored" || this.selectedDesign.svg_data && this.selectedDesign.svg_data[sideName] != null;
    },
    setActiveSide(sideName) {
      this.setField({
        field: "activeSide",
        value: sideName,
      });
    },
  },
  components: {
    Vector,
  },
  data() {
    return {
      //sides: ["front", "side", "back"],
      sides: ["front", "back"],
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  .product-preview {
    max-width: 515px;
    width: 40%;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000040;
    padding: 25px;
    .product-preview-sides {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      .product-preview-side-wrapper {
        margin: 0 1px;
        .product-preview-side {
          padding: 5px 13px;
          background-color: #f9f9f9;
          color: #3e3e3e;
          font-size: 12px;
          line-height: 13px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          border-radius: 15px;
          box-sizing: border-box;
          border: 1px solid transparent;
          &.active,
          &:hover {
            border: 1px solid #000;
          }
        }
      }
    }
    @media screen and (max-width: 780px) {
      width: 90%;
      margin: 5px 0;
    }
    .product-preview-vector-container {
      min-height: 400px;
    }
  }
}
</style>